import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/Title";
import { SimpleGrid } from "@chakra-ui/react";
import DictionaryHeader from "../components/Dictionary/DictionaryHeader";
import DictionaryBlock from "../components/Dictionary/DictionaryBlocks";
import DictionaryView from "../components/Dictionary/DictionaryView";
import { useQuery } from "@tanstack/react-query";
import { getDictionaryStats, callWithAuth, getUserInfo } from "../api/Api";

const DictionaryPage: React.FC = () => {
  const [viewType, setViewType] = useState<"Known" | "Learning" | null>(null);
  const nav = useNavigate();
  const topicsAvailable = [
    {
      Title: "Animals",
      Description: "Learn about different animals and their habitats.",
    },
    {
      Title: "Travel",
      Description: "Essentials for traveling and exploring new places.",
    },
  ];

  const dictionaryStatsQuery = useQuery({
    queryKey: ["dictionaryStats"],
    queryFn: () => {
      return callWithAuth(getDictionaryStats);
    },
  });

  const dictionaryStats = dictionaryStatsQuery.data || {
    knownWordCount: 0,
    learningWordCount: 0,
  };

  const userInfoQuery = useQuery({
    queryKey: ["userInfo"],
    queryFn: () => {
      return callWithAuth(getUserInfo);
    },
  });

  const firstName = userInfoQuery.data?.firstName || "";

  const handleLearningClick = () => {
    setViewType("Learning");
  };

  const handleKnownClick = () => {
    setViewType("Known");
  };

  const handleTopicClick = () => {
    nav("/construction");
  };

  const handleCloseClick = () => {
    setViewType(null);
  };

  return (
    <div className="flex flex-col justify-start w-full h-full pt-16 gap-4">
      <Title text={firstName + "'s Dictionary"} />
      <DictionaryHeader
        onKnownClick={handleKnownClick}
        onLearningClick={handleLearningClick}
        wordsKnown={dictionaryStats.knownWordCount}
        wordsLearning={dictionaryStats.learningWordCount}
      />
      {viewType === null ? (
        <>
          <Title text="Review" />
          <SimpleGrid columns={2} spacing={3}>
            <DictionaryBlock
              icon="📙"
              title="Learning"
              subtitle={
                <span>
                  You have{" "}
                  <span className="font-bold text-[var(--color-orange)]">
                    {dictionaryStats.learningWordCount}
                  </span>{" "}
                  words to practice
                </span>
              }
              onClick={handleLearningClick}
            />
            <DictionaryBlock
              icon="📘"
              title="Known Words"
              subtitle={
                <span>
                  You have{" "}
                  <span className="font-bold text-[var(--color-blue)]">
                    {dictionaryStats.knownWordCount}
                  </span>{" "}
                  words to master
                </span>
              }
              onClick={handleKnownClick}
            />
          </SimpleGrid>
          <Title text="Topics" />
          <SimpleGrid columns={2} spacing={3}>
            {topicsAvailable.map((topic, index) => (
              <DictionaryBlock
                key={index}
                icon="📚"
                title={topic.Title}
                subtitle={topic.Description}
                onClick={() => handleTopicClick()}
              />
            ))}
          </SimpleGrid>
        </>
      ) : (
        <DictionaryView type={viewType} onClose={handleCloseClick} />
      )}
    </div>
  );
};

export default DictionaryPage;
