import React from "react";
import { TextBoxWithFlag, Flag, Text } from "./TextBoxWithFlag";
import { GB } from "country-flag-icons/react/3x2";

interface TranslationProps {
  targetWord: string;
  nativeWord: string;
  className?: string;
}

const Translation: React.FC<TranslationProps> = ({
  targetWord,
  nativeWord,
  className,
}) => {
  const highlightColorBlueAndCls = "bg-[#0C92F1] text-white rounded px-0.5";
  return (
    <TextBoxWithFlag className={className}>
      <Flag>
        <GB title="GB" className="w-8" />
      </Flag>
      <Text>
        <span className={highlightColorBlueAndCls}>"{nativeWord}"</span>
        {" - " + targetWord}
      </Text>
    </TextBoxWithFlag>
  );
};

export default Translation;
