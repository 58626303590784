import React, { useState, useEffect } from "react";
import NameInput from "../components/NameInput";
import EmailInput from "../components/EmailInput";
import Chat from "../components/Chat/Chat";
import { PostTempUserRequest, postTempUser } from "../api/Api";
import { parrotUserStorage } from "../api/parrotUserStorage";
import { tokenStorage } from "../api/tokenStorage";
import { useParams } from "react-router-dom";

const OnboardingPage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { name: nameParam, email: emailParam } = useParams();

  const handleNameSubmit = (inputName: string) => {
    setName(inputName);
  };

  useEffect(() => {
    if (nameParam && emailParam) {
      setName(nameParam);
      setEmail(emailParam);
    }
  }, [nameParam, emailParam]);

  const handleEmailSubmit = async (inputEmail: string) => {
    setEmail(inputEmail);
    const req: PostTempUserRequest = {
      firstName: name,
      email: inputEmail,
    };
    let res;
    try {
      res = await postTempUser(req);
    } catch (err) {
      // TODO, handle some UI error logic here
      // my suggestion is have a simple function that activates a global error modal
    }
    if (!res) {
      return;
    }
    tokenStorage.setToken(res.token);
    parrotUserStorage.setId(res.parrotUserId);
  };

  const resetEmail = () => {
    setEmail("");
  };

  const resetName = () => {
    setName("");
  };

  return (
    <div className="appPadding flex flex-col h-full w-full">
      {name === "" ? (
        <NameInput onNameSubmit={handleNameSubmit} />
      ) : email === "" ? (
        <EmailInput onEmailSubmit={handleEmailSubmit} onNameReset={resetName} />
      ) : (
        <Chat
          disableBack={nameParam && emailParam ? true : false}
          name={name}
          onEmailReset={resetEmail}
        />
      )}
    </div>
  );
};

export default OnboardingPage;
