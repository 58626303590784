import React from "react";
import { useNavigate } from "react-router-dom";
import ChapterNodeCompleted from "./ChapterNodeCompleted";
import ChapterNodeInProgress from "./ChapterNodeInProgress";
import ChapterNodeLocked from "./ChapterNodeLocked";
import { Chapter } from "../../Types";
import { getIsFirstLessonClick, callWithAuth } from "../../../api/Api";
import { useQuery } from "@tanstack/react-query";

interface CoursePathProps {
  chapters: Chapter[];
}

const marginClasses = [
  "ml-0",
  "-ml-24",
  "-ml-40",
  "-ml-32",
  "-ml-8",
  "ml-8",
  "ml-28",
  "ml-16",
  "ml-0",
  "-ml-24",
  "-ml-40",
  "-ml-32",
];

const getMarginClass = (index: number): string => {
  return marginClasses[index % marginClasses.length];
};

const CoursePath: React.FC<CoursePathProps> = ({ chapters }) => {
  const navigate = useNavigate();

  const firstLessonQuery = useQuery({
    queryKey: ["firstLessonClick"],
    queryFn: () => {
      return callWithAuth(getIsFirstLessonClick);
    },
  });

  const isFirstLessonClick = firstLessonQuery.data?.isFirstClick || false;

  const handleChapterClick = (chapterId: number, index: number) => {
    if (index === 0) {
      if (isFirstLessonClick) {
        navigate(`/info/${chapterId}`);
      } else {
        navigate(`/chapter/${chapterId}`);
      }
    } else {
      navigate(`/chapter/${chapterId}`);
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-full py-4 overflow-y-scroll gap-4 no-scrollbar">
      {chapters.map((chapter, index) => {
        const alignmentClass =
          index > 0 ? getMarginClass(index) : "self-center";
        const ChapterNodeComponent =
          chapter.status === "completed"
            ? ChapterNodeCompleted
            : chapter.status === "current"
              ? ChapterNodeInProgress
              : ChapterNodeLocked;

        return (
          <div key={chapter.chapterId} className={`w-1/2 ${alignmentClass}`}>
            <ChapterNodeComponent
              onClick={() => handleChapterClick(chapter.chapterId, index)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CoursePath;
