import React from "react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import { Avatar, Box, Flex, Text, Stack } from "@chakra-ui/react";
import { PiSealCheck } from "react-icons/pi";
import { CiClock2 } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import Title from "./Title";
import { ES } from "country-flag-icons/react/3x2";
import { GetUserInfoResponse } from "../api/Api.tsx";

interface ProfileCardProps {
  level: number;
  user: GetUserInfoResponse;
}

const ProfileCard: React.FC<ProfileCardProps> = ({level, user}) => {
 
  const firstName = user.firstName || "";
  const email = user.email || "";
  const joined = user.joinDate || "";
  const pfp = user.avatarUrl || "";

  return (
    <Card
      align="start"
      direction="row"
      justify="between"
      className="w-full h-auto"
    >
      <Flex w="full" justify="space-between" className="p-2">
        <Stack>
          <CardHeader>
            <Title text={firstName} />

            <Box>
              <Flex align="center" mb={1}>
                <PiSealCheck className="w-4 h-4 mr-2 text-[#979797]" />
                <Text className="text-sm font-normal text-[#979797]">
                  Level {level}
                </Text>
              </Flex>
              <Flex align="center" mb={1}>
                <CiClock2 className="w-4 h-4 mr-2 text-[#979797]" />
                <Text className="text-sm font-normal text-[#979797]">
                  Joined {joined}
                </Text>
              </Flex>
              {email !== "" && (
                <Flex align="center">
                  <AiOutlineMail className="w-4 h-4 mr-2 text-[#979797]" />
                  <Text
                    title={email}
                    className="text-sm font-normal text-[#979797] max-w-36 sm:max-w-80 overflow-hidden text-nowrap text-ellipsis"
                  >
                    {email}
                  </Text>
                </Flex>
              )}
            </Box>
          </CardHeader>
          <CardBody>
            <ES className="w-8" />
          </CardBody>
        </Stack>
        <div>
          <Avatar size="xl" src={pfp} />
        </div>
      </Flex>
    </Card>
  );
};

export default ProfileCard;
