import React from "react";
import { Avatar, AvatarBadge } from "@chakra-ui/react";
import BackButton from "../Buttons/BackButton";
import logo from "../../assets/ParrotLogo.png";

interface ChatHeaderProps {
  handleBack: () => void;
  disableBack?: boolean;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ handleBack, disableBack }) => {
  return (
    <div className="flex flex-row items-center justify-between w-full py-4">
      {!disableBack && <BackButton handleBack={handleBack} />}
      <div className="flex flex-row items-center absolute left-1/2 transform -translate-x-1/2">
        <Avatar src={logo} size="md" mr={4}>
          <AvatarBadge boxSize="1.1em" bg="green.500" />
        </Avatar>
        <div className="flex flex-col items-start justify-center">
          <div className="text-black text-md font-bold">Parrot</div>
          <div className="text-gray-500 text-sm font-normal">Always active</div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
