import React, { useRef } from "react";
import ParrotMessage from "./ParrotMessage";
import ChatOptions from "./ChatOptions";
import { formatChatDate } from "../../lib/utils";
import ResponseMessage from "./ResponseMessage";

interface ChatBodyProps {
  messages: Array<{
    text: React.ReactNode[];
    options?: string[];
    condition?: (selectedOptions: string[]) => boolean;
  }>;
  currentStep: number;
  selectedOptions: string[];
  handleOptionClick: (option: string) => void;
}

const ChatBody: React.FC<ChatBodyProps> = ({
  messages,
  currentStep,
  selectedOptions,
  handleOptionClick,
}) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const setChatEndRef = (element: HTMLDivElement | null) => {
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    chatEndRef.current = element;
  };

  return (
    <div className="flex flex-col items-center justify-center w-full p-4 gap-2">
      <div className="text-xs text-[#72777A] mb-4">
        {formatChatDate(new Date())}
      </div>
      {messages.slice(0, currentStep + 1).map((message, index) => (
        <div
          key={index}
          className="w-full h-full overflow-y-scroll pb-2 no-scrollbar"
        >
          {message.text.map((text, textIndex) => (
            <ParrotMessage key={textIndex} message={text} />
          ))}
          {selectedOptions[index] && (
            <ResponseMessage message={selectedOptions[index]} />
          )}
        </div>
      ))}
      {currentStep < messages.length && messages[currentStep].options && (
        <div className="w-full">
          <ChatOptions
            options={messages[currentStep].options || []}
            onOptionClick={handleOptionClick}
          />
        </div>
      )}
      <div ref={setChatEndRef} />
    </div>
  );
};

export default ChatBody;
