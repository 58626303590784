import React from 'react';
import { FaUser } from 'react-icons/fa';

interface ProfileButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ isActive, onClick }) => {

  return (
    <button
      onClick={onClick}
      className={`h-20 w-20 rounded-lg ${
        isActive ? 'text-black' : 'text-gray-500'
      }`}
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <FaUser className="h-8 w-8" />
        <p className="text-sm font-medium">{'profile'}</p>
      </div>
    </button>
  );
};

export default ProfileButton;