// parrotUserStorage.ts

const PARROT_USER_ID_KEY = "parrotUserId";

export const parrotUserStorage = {
  // Function to retrieve the parrotUserId
  // returns 0 if theres no id set
  getId: (): number => {
    const pUid = localStorage.getItem(PARROT_USER_ID_KEY);
    if (!pUid) return 0;
    return parseInt(pUid);
  },

  // Function to set the parrotUserId
  setId: (parrotUserId: number): void => {
    const pUid = `${parrotUserId}`;
    localStorage.setItem(PARROT_USER_ID_KEY, pUid);
  },

  // Function to check if the parrotUserId is set
  isSet: (): boolean => {
    const parrotUserId = localStorage.getItem(PARROT_USER_ID_KEY);
    return parrotUserId !== null && parrotUserId !== "";
  },

  // Function to clear the parrotUserId
  clear: (): void => {
    localStorage.removeItem(PARROT_USER_ID_KEY);
  },
};
