import {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import ReactPlayer from "react-player/youtube";
import { AspectRatio } from "@chakra-ui/react";
import { IoMdPlay } from "react-icons/io";
import { callWithAuth, postListenTime, PostListenTimeRequest } from "@/api/Api";

interface VideoPlayerProps {
  url: string;
  onTimeUpdate: (time: number) => void;
  startTime: number;
  endTime: number;
  onPlay: () => void;
  onTrackListeningTime: (time: number) => void | undefined;
}

export interface VideoPlayerHandle {
  seekTo: (time: number) => void;
  pause: () => void;
}

const VideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
  (
    { url, onTrackListeningTime, onTimeUpdate, startTime, endTime, onPlay },
    ref,
  ) => {
    const playerRef = useRef<ReactPlayer>(null);
    const [playing, setPlaying] = useState(false);

    useImperativeHandle(ref, () => ({
      seekTo(time) {
        playerRef.current?.seekTo(time / 1000, "seconds");
      },
      pause() {
        setPlaying(false);
      },
    }));

    const handlePause = () => {
      if (playing !== false) {
        setPlaying(false);
      }
    };

    const handlePlay = () => {
      if (playing !== true) {
        setPlaying(true);
        onPlay();
      }
    };

    const handleOverlayClick = () => {
      if (playing !== true) {
        setPlaying(true);
        onPlay();
      } else {
        setPlaying(false);
      }
    };

    // tracks the watch time every X seconds
    // no big deal if this requests fails for whatever reason
    const trackWatchTime = (startTimeMs: number) => {
      let lastMeasuredTimeSeconds = startTimeMs / 1000;
      const intervalTimeSeconds = 2;
      const trackWatchTime = setInterval(() => {
        try {
          // kill captions from the youtube video
          // this is naughty
          (playerRef.current as any).player.player.player.unloadModule("cc");
          (playerRef.current as any).player.player.player.unloadModule(
            "captions",
          );
        } catch (err) {
          console.warn(err);
        }
        if (playerRef.current && playerRef.current.getCurrentTime) {
          const currentTimeSeconds = playerRef.current.getCurrentTime();
          // realistic amount of watch time passed, post it
          // gives us space for a valid time add on the high end
          const timeBuffer = 2;
          const timeDiff = currentTimeSeconds - lastMeasuredTimeSeconds;
          if (0 < timeDiff && timeDiff <= intervalTimeSeconds + timeBuffer) {
            const timeDiffFloored = Math.floor(timeDiff);
            if (onTrackListeningTime) {
              onTrackListeningTime(timeDiffFloored);
            }
            const data: PostListenTimeRequest = { listenTime: timeDiffFloored };
            callWithAuth(postListenTime, data).catch((err) => {
              console.error(err);
            });
          }
          lastMeasuredTimeSeconds = currentTimeSeconds;
        }
      }, intervalTimeSeconds * 1000);
      return trackWatchTime;
    };

    useEffect(() => {
      const interval = setInterval(() => {
        if (playerRef.current && playerRef.current.getCurrentTime) {
          const currentTime = playerRef.current.getCurrentTime() * 1000;
          if (currentTime >= endTime) {
            playerRef.current.seekTo(startTime / 1000, "seconds");
          }
          onTimeUpdate(currentTime);
        }
      }, 100);
      return () => {
        clearInterval(interval);
      };
    }, [startTime, endTime, onTimeUpdate, playing]);

    // tracking the watch time
    useEffect(() => {
      const watchTimeInterval = trackWatchTime(startTime);
      return () => {
        clearInterval(watchTimeInterval);
      };
    }, [startTime, endTime]);

    return (
      <div className="w-full max-w-[534px] relative mt-3">
        <AspectRatio ratio={16 / 9} className="p-0">
          <ReactPlayer
            ref={playerRef}
            url={url + `?start=${startTime / 1000}`}
            playing={playing}
            onPlay={handlePlay}
            onPause={handlePause}
            height="100%"
            width="100%"
          />
        </AspectRatio>
        {!playing ? (
          <div
            onClick={handleOverlayClick}
            className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          >
            {/* Content inside the overlay, if needed */}
            <IoMdPlay color="white" size="2em" />
          </div>
        ) : (
          <div
            onClick={handleOverlayClick}
            className="absolute inset-0 bottom-9"
          ></div>
        )}
      </div>
    );
  },
);

export default VideoPlayer;
