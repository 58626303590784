import React from "react";

interface ChatButtonProps {
  text: string;
  onClick: () => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ text, onClick }) => {
  return (
    <button onClick={onClick}>
      <div className="bg-[#F2F8FF] text-[var(--color-blue)] rounded-xl p-3 w-auto text-center shadow-lg">
        {text}
      </div>
    </button>
  );
};

export default ChatButton;
