import TabContent from "../components/Tabs/TabContent";
import React from "react";
import LearnTab from "../components/Tabs/LearnTab";
import GrowTab from "../components/Tabs/GrowTab";
import { useOutletContext } from "react-router-dom";

interface LearnPageProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
}

const LearnPage: React.FC = () => {
  const { activeTab, setActiveTab } = useOutletContext<LearnPageProps>();
  const content = [<LearnTab key="learn" />, <GrowTab key="grow" />];

  return (
    <div className="w-full h-full items-center pt-16">
      <TabContent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        content={content}
      />
    </div>
  );
};

export default LearnPage;
