import React, { useEffect, useState, useMemo, useRef } from "react";
import { Button } from "../Buttons/Button";
import "../../Animations.css";
import { MatchingActivity, MatchData } from "../Types";
import Title from "@/components/Title";

const shuffleArray = (matchingData: MatchData[]) => {
  return matchingData.slice().sort(() => Math.random() - 0.5);
};

interface MatchProps {
  matchActivity: MatchingActivity;
  onActivityFinished: () => void;
  lessonId: number;
}

type State = "start" | "selected" | "incorrect" | "matched";

const getBackgroundColor = (state: State) => {
  if (state == "start") return "";
  if (state === "incorrect") return "var(--color-red)";
  if (state === "matched") return "var(--color-green)";
  if (state === "selected") return "var(--color-blue)";
  return "";
};

interface MatchButtonState {
  id: number;
  spanish: string;
  english: string;
  state: State;
}

const Match: React.FC<MatchProps> = ({
  lessonId,
  matchActivity,
  onActivityFinished,
}) => {
  const shuffledMatchData = useMemo(
    () => shuffleArray(matchActivity.matchData),
    [lessonId],
  );
  const initEnglishState: MatchButtonState[] = shuffledMatchData.map(
    (button: MatchData, index) => {
      return {
        id: index,
        spanish: button.spanish,
        english: button.english,
        state: "start" as State,
      };
    },
  );
  // for one side of button
  const initSpanishState: MatchButtonState[] = matchActivity.matchData.map(
    (button: MatchData, index) => {
      return {
        id: index,
        spanish: button.spanish,
        english: button.english,
        state: "start" as State,
      };
    },
  );
  const [englishButtons, setEnglishButtons] =
    useState<MatchButtonState[]>(initEnglishState);
  const [spanishButtons, setSpanishButtons] =
    useState<MatchButtonState[]>(initSpanishState);

  const matchedCount = englishButtons.reduce((count, button) => {
    if (button.state == "matched") {
      return count + 1;
    }
    return count;
  }, 0);

  const lastButtonClicked = useRef<MatchButtonState | null>(null);
  const lastClickedType = useRef<"es" | "en" | null>(null);
  // causes side effect of activity ending
  const win = matchedCount == englishButtons.length;

  useEffect(() => {
    if (win) {
      onActivityFinished();
    }
  }, [win]);

  // a little helper function to update the button state
  const updateSpanishButtonState = (id: number, newState: State) => {
    setSpanishButtons((prevState: MatchButtonState[]) => {
      return prevState.map((button: MatchButtonState) => {
        const newButton = {
          ...button,
        };
        if (button.id == id) {
          newButton.state = newState;
          return newButton;
        }
        return button;
      });
    });
  };
  // a little helper function to update the button state
  const updateEnglishButtonState = (id: number, newState: State) => {
    setEnglishButtons((prevState: MatchButtonState[]) => {
      return prevState.map((button: MatchButtonState) => {
        const newButton = {
          ...button,
        };
        if (button.id == id) {
          newButton.state = newState;
          return newButton;
        }
        return button;
      });
    });
  };
  // If spanish is the first to be clicked we just update the selected state,
  // If spanih is clicked the second time it means english was clicked and we
  // need to check if the selected spanish word (last clicked) has the same english
  // as the clicked word. That qualifies as a match
  const handleSpanishClick = (button: MatchButtonState) => {
    if (button.state == "matched" || lastClickedType.current == "es") {
      //ignore these clicks
      return;
    }
    if (!lastButtonClicked.current) {
      updateSpanishButtonState(button.id, "selected");
      lastButtonClicked.current = button;
      lastClickedType.current = "es";
      return;
    }
    const matches = lastButtonClicked.current.english === button.english;
    if (matches) {
      updateSpanishButtonState(button.id, "matched");
      // wont be null if top check is good
      updateEnglishButtonState(lastButtonClicked.current.id, "matched");
      lastButtonClicked.current = null;
      lastClickedType.current = null;
      return;
    }
    // no match
    updateSpanishButtonState(button.id, "incorrect");
    updateEnglishButtonState(lastButtonClicked.current.id, "incorrect");
    const lastClickedId = lastButtonClicked.current.id;
    lastButtonClicked.current = null;
    lastClickedType.current = null;
    setTimeout(() => {
      updateSpanishButtonState(button.id, "start");
      updateEnglishButtonState(lastClickedId, "start");
    }, 300);
    return;
  };

  // see a helpful comment on handleSpanishClick
  const handleEnglishClick = (button: MatchButtonState) => {
    if (button.state == "matched" || lastClickedType.current == "en") {
      //ignore these clicks
      return;
    }
    if (!lastButtonClicked.current) {
      updateEnglishButtonState(button.id, "selected");
      lastButtonClicked.current = button;
      lastClickedType.current = "en";
      return;
    }
    // we have a match
    const matches = lastButtonClicked.current.spanish === button.spanish;
    if (matches) {
      updateEnglishButtonState(button.id, "matched");
      updateSpanishButtonState(lastButtonClicked.current.id, "matched");
      lastButtonClicked.current = null;
      lastClickedType.current = null;
      return;
    }
    updateEnglishButtonState(button.id, "incorrect");
    updateSpanishButtonState(lastButtonClicked.current.id, "incorrect");
    const lastClickedId = lastButtonClicked.current.id;
    lastButtonClicked.current = null;
    lastClickedType.current = null;
    setTimeout(() => {
      updateEnglishButtonState(button.id, "start");
      updateSpanishButtonState(lastClickedId, "start");
    }, 300);
    return;
  };

  return (
    <>
      <div className="appPadding flex flex-col items-center w-full">
        <Title text="Match the words" />
        <div className="flex flex-row gap-16 pt-16 w-full px-4 items-center justify-center">
          <div className="flex flex-col gap-5">
            {spanishButtons.map((button: MatchButtonState) => {
              const bgColor = getBackgroundColor(button.state);
              const shake = button.state == "incorrect";
              return (
                <Button
                  variant="default"
                  className={shake ? "strong-hover-shake" : ""}
                  style={{
                    backgroundColor: bgColor,
                    color: "white",
                  }}
                  key={button.id}
                  onClick={() => handleSpanishClick(button)}
                >
                  {button.spanish}
                </Button>
              );
            })}
          </div>
          <div className="flex flex-col gap-5">
            {englishButtons.map((button: MatchButtonState) => {
              const bgColor = getBackgroundColor(button.state);
              const shake = button.state == "incorrect";
              return (
                <Button
                  variant="default"
                  className={shake ? "strong-hover-shake" : ""}
                  style={{
                    backgroundColor: bgColor,
                    color: "white",
                  }}
                  key={button.id}
                  onClick={() => handleEnglishClick(button)}
                >
                  {button.english}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Match;
