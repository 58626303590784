import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";

interface WordButtonProps {
  isKnown: boolean;
  onClick: () => void;
}

const AddWordButton: React.FC<WordButtonProps> = ({ isKnown, onClick }) => {
  const [bounce, setBounce] = useState(false);

  useEffect(() => {
    if (isKnown) {
      setBounce(true);
      const timer = setTimeout(() => setBounce(false), 1500);
      return () => clearTimeout(timer);
    } else {
      setBounce(false);
    }
  }, [isKnown]);

  return (
    <button
      onClick={onClick}
      className={`
        flex
        items-center
        gap-2
        text-sm
        px-2
        py-[2px]
        rounded-2xl
        border-solid
        border-gray-200
        ${isKnown ? "bg-[var(--color-green)] text-white" : ""}
        ${bounce ? "animate-bounce" : ""}
      `}
    >
      {isKnown ?<>🥳</> : <FaPlus className="w-3 h-3" />}
      <div className="text-nowrap">
        {isKnown ? "Added to Known" : "Add to Known"}
      </div>
    </button>
  );
};

export default AddWordButton;