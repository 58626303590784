import React from 'react';
import { PiGraduationCapFill } from 'react-icons/pi';

interface LearnButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const LearnButton: React.FC<LearnButtonProps> = ({ isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`w-20 h-20 ${isActive ? 'text-black' : 'text-gray-500'}`}
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <PiGraduationCapFill className="h-8 w-8" />
        <p className="text-sm font-medium">{'learn'}</p>
      </div>
    </button>
  );
};

export default LearnButton;