import React, { useState } from "react";
import CourseMenu from "./CourseMenu";
import CoursePath from "./CoursePath/CoursePath";
import { useQuery } from "@tanstack/react-query";
import {
  getCourseInfo,
  callWithAuth,
  GetCourseInfoRequest,
} from "../../api/Api";

const CourseView: React.FC = () => {
  const [courseId, setCourseId] = useState(1);

  const courseInfoQuery = useQuery({
    queryKey: ["courseInfo"],
    queryFn: () => {
      const data: GetCourseInfoRequest = {
        courseId: courseId,
      };
      return callWithAuth(getCourseInfo, data);
    },
  });

  const course = courseInfoQuery.data?.course;
  const chapters = courseInfoQuery.data?.chapters || [];

  const courseTitle = course?.title || "";

  return (
    <div className="flex flex-col w-full justify-start overflow-scroll gap-4 no-scrollbar">
      <CourseMenu title={courseTitle} onSelectCourse={setCourseId} />
      <div className="w-full h-auto overflow-scroll z-0 no-scrollbar">
        <CoursePath chapters={chapters} />
      </div>
    </div>
  );
};

export default CourseView;
