import React from "react";

interface TabButtonsProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  tabNames: string[];
}

const TabButtons: React.FC<TabButtonsProps> = ({ activeTab, setActiveTab, tabNames }) => {
  return (
    <div className="flex justify-center items-center relative">
      {tabNames.map((name, index) => (
        <div key={index} className="flex flex-col items-center mx-1">
          <button
            className={`pt-2 ${activeTab === index ? "text-black font-medium" : "text-gray-600"}`}
            onClick={() => setActiveTab(index)}
          >
            {name}
          </button>
          {activeTab === index && <div className="w-1 h-1 bg-black rounded-full"></div>}
        </div>
      ))}
    </div>
  );
};

export default TabButtons;