import React, { useState } from "react";
import { TextBoxWithFlag, Flag, Text } from "./TextBoxWithFlag.tsx";
import { ES } from "country-flag-icons/react/3x2";
import { UserVideoWord, UserWordStatus } from "../Types.tsx";

interface ParrotWordProps {
  word: UserVideoWord;
  position: number;
  onWordClick: (word: UserVideoWord) => void;
  highlightPosition: number;
  onDoubleWordClick: (clickedWord: UserVideoWord) => void;
}

const ParrotWord: React.FC<ParrotWordProps> = (props) => {
  // its a blue, a yellow and a light yellow
  // TODO - refactor these to theme tailwind colors
  const newColorDarkYellow = "bg-[#FECA00]";
  const learningColorLightYellow = "bg-[#FFEBA1]";
  const highlightColorBlue = "bg-[#0C92F1]";
  const status = props.word.status;
  const [lastTap, setLastTap] = useState(0);

  const wordCls = (status: UserWordStatus) => {
    switch (status) {
      case "known":
        return "bg-transparent";
      case "learning":
        return learningColorLightYellow;
      case "new":
        return newColorDarkYellow;
      default:
        break;
    }
  };
  const handleClick = async () => {
    const currentTime = new Date().getTime();
    const tapGap = currentTime - lastTap;
    if (tapGap < 300 && tapGap > 0) {
      // 300ms as the threshold for double-tap
      handleDoubleClick();
    } else {
      props.onWordClick(props.word);
    }
    setLastTap(currentTime);
  };

  const handleDoubleClick = () => {
    props.onDoubleWordClick(props.word);
  };

  return (
    <span
      className="cursor-pointer selection:text-white"
      key={props.word.videoWordId}
    >
      <span
        data-pos={props.position}
        className={
          (props.position === props.highlightPosition
            ? highlightColorBlue + " text-white"
            : wordCls(status)) + " rounded px-0.5"
        }
        onClick={handleClick}
      >
        {props.word.displayText}
      </span>{" "}
    </span>
  );
};

interface HighlightedTextProps {
  words: UserVideoWord[];
  highlightPosition: number;
  onWordClick: (word: UserVideoWord) => void;
  onDoubleWordClick: (clickedWord: UserVideoWord) => void;
}

const HighlightedWords: React.FC<HighlightedTextProps> = ({
  words,
  highlightPosition,
  onWordClick,
  onDoubleWordClick,
}) => {
  return (
    <p>
      {" "}
      {words.map((word, index) => (
        <ParrotWord
          key={word.videoWordId}
          word={word}
          position={index}
          highlightPosition={highlightPosition}
          onWordClick={onWordClick}
          onDoubleWordClick={onDoubleWordClick}
        />
      ))}
    </p>
  );
};

interface SubtitleProps {
  userVideoWords: UserVideoWord[];
  className?: string;
  highlightIndex: number;
  onWordClick: (clickedWord: UserVideoWord) => void;
  onDoubleWordClick: (clickedWord: UserVideoWord) => void;
}

const Subtitle: React.FC<SubtitleProps> = ({
  userVideoWords,
  className,
  highlightIndex,
  onWordClick,
  onDoubleWordClick,
}) => {
  return (
    <>
      <TextBoxWithFlag className={className}>
        <Flag>
          <ES title="Spain" className="w-8" />
        </Flag>
        <Text>
          <HighlightedWords
            words={userVideoWords}
            highlightPosition={highlightIndex}
            onWordClick={onWordClick}
            onDoubleWordClick={onDoubleWordClick}
          />
        </Text>
      </TextBoxWithFlag>
    </>
  );
};

export default Subtitle;
