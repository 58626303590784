import React from "react";
import { Card, CardBody } from "@chakra-ui/react";
import StatDiff from "./StatDiff.tsx";

interface StatBlockProps {
  preText?: string;
  value: number;
  postText?: string;
  subtitle: string;
  statDiff?: number; // amount the stat increased in the lesson, if exist we will show a little icon in the top right
}

const StatBlock: React.FC<StatBlockProps> = ({
  value,
  preText,
  postText,
  subtitle,
  statDiff,
}) => {
  const displayStatDiff = statDiff && statDiff > 0 ? statDiff : 0;
  const title = (preText || "") + `${value}` + (postText || "");
  return (
    <Card zIndex={0} width="100%" height="100%" className="relative">
      {statDiff != undefined && (
        <StatDiff
          className="absolute -top-5 -right-2 z-10"
          value={displayStatDiff}
        />
      )}
      <CardBody className="flex flex-col w-full items-center justify-center gap-2">
        <h1 className="text-xl font-bold">{title}</h1>
        <p className="text-xs sm:text-sm font-normal">{subtitle}</p>
      </CardBody>
    </Card>
  );
};

const formatTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

interface TimeStatBlockProps {
  timeSeconds: number;
  preText?: string;
  postText?: string;
  statDiff?: number;
}

// For the special case of handing time stats
const TimeStatBlock: React.FC<TimeStatBlockProps> = ({
  timeSeconds,
  preText,
  statDiff, // also in seconds
  postText,
}) => {
  const { hours, minutes, seconds } = formatTime(timeSeconds);
  let title = "";
  let displayPreText = preText;
  const displayStatDiff = statDiff && statDiff > 0 ? statDiff : 0;
  const subtitle = "Listened";

  // Generate the display logic
  if (hours > 0) {
    displayPreText = "";
    if (statDiff == undefined || statDiff == 0) {
      title = `${hours}h ${minutes}m `;
    } else {
      title = `${hours}h ${minutes}m ${seconds}s`;
    }
    if (minutes == 0 && seconds == 0) {
      displayPreText = preText;
      title = `${hours}h`;
    }
  } else if (minutes > 0) {
    if (seconds == 0) {
      title = `${minutes} m`;
      if (minutes == 1) {
        title = `${minutes} m`;
      }
    } else if (minutes == 1 && seconds == 0) {
      title = `${minutes} m`;
    } else if (minutes >= 1 && seconds != 0) {
      displayPreText = "";
      title = `${minutes}m ${seconds}s`;
    }
  } else {
    title = `${seconds} s`;
    if (seconds == 1) {
      title = `${seconds} s`;
    }
  }

  title = `${displayPreText || ""}${title}${postText || ""}`;

  return (
    <Card zIndex={0} width="100%" className="relative">
      <CardBody className="flex flex-col w-full items-center justify-center gap-3">
        {statDiff != undefined && (
          <StatDiff
            className="absolute -top-5 -right-2 z-10"
            value={displayStatDiff}
            postText="s"
          />
        )}
        <h1 className="text-xl font-bold">{title}</h1>
        <p className="text-xs sm:text-sm font-normal">{subtitle}</p>
      </CardBody>
    </Card>
  );
};

export { StatBlock, TimeStatBlock };
