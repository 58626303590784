import React from "react";
import { Card } from "@chakra-ui/react";
import LessonIcon from "./LessonIcon";

interface LessonBlockProps {
  lessonNumber: number;
  description: string;
  status: "completed" | "current" | "locked";
  onClick: () => void;
}

const LessonBlock: React.FC<LessonBlockProps> = ({
  lessonNumber,
  description,
  status,
  onClick,
}) => {
  return (
    <button onClick={onClick} className="w-full h-auto">
      <Card
        variant="shadow"
        align="start"
        alignItems="center"
        direction="row"
        overflow="hidden"
        paddingY="9px"
        paddingX="12px"
      >
        <LessonIcon status={status} />
        <div className="ml-4 text-start gap-0">
          <div className="text-[10px] font-normal">Lesson {lessonNumber}</div>
          <div className="text-sm font-semibold">{description}</div>
        </div>
      </Card>
    </button>
  );
};

export default LessonBlock;
