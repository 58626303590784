import React from "react";
import { Button } from "./Button";

interface ContinueButtonProps {
  onClick: () => void;
  text?: string;
}

const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick, text }) => {
  return (
    <Button
      variant="default"
      onClick={onClick}
      className={`w-full h-10 text-center rounded-xl text-white text-lg font-bold bg-[var(--color-blue)] break-words`}
    >
      {text || "Continue 🏃‍♂️"}
    </Button>
  );
};

export default ContinueButton;
