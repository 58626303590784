import React from "react";
import ContinueButton from "../components/Buttons/ContinueButton";
import { Button } from "../components/Buttons/Button";
import LogoAndText from "../components/LogoAndText";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../api/Api";
import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";

const StartPage: React.FC = () => {
  const loggedIn = useQuery({
    queryKey: ["AuthQueryStartPage"],
    queryFn: () => {
      return isLoggedIn();
    },
  });
  const nav = useNavigate();
  const shouldRedirect = loggedIn.data || undefined;
  if (shouldRedirect) {
    return <Navigate to="/home/learn" />;
  }

  const handleContinue = () => {
    nav("/onboarding");
  };

  const handleLogin = () => {
    nav("/login");
  };

  return (
    <div className="appPadding flex flex-col h-full w-full overflow-hidden items-center z-0">
      <div className="flex flex-col justify-end items-center w-full flex-grow flex-shrink basis-3/5">
        <LogoAndText />
        <div className="w-48 text-center mt-4">
          <span>The </span>
          <span className="font-bold">#1 way to learn</span>
          <span> a language is finally here</span>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full gap-2 z-10 p-2 flex-grow flex-shrink basis-2/5">
        <Button
          variant="default"
          onClick={handleContinue}
          className={`mt-auto w-full h-10 text-center rounded-xl text-white text-lg font-bold break-words`}
        >
          Continue as guest 🏃‍♂️
        </Button>
        <ContinueButton onClick={handleLogin} text="Login/Signup" />
      <div className="mt-auto text-[11px] text-gray-500">
        <a href="/privacy">Privacy Policy</a>
        <span className="mx-2">|</span>
        <a href="/terms">Terms of Service</a>
      </div>
      </div>
    </div>
  );
};

export default StartPage;
