// theme.ts
import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { cardTheme } from "./Card";
import { tagTheme } from "./Tag";

// Define the brand colors
const colors = {
    brand: {
      orange: "#f06b00",
      blue: "#0c92f1",
      grey: "#424242",
      yellow: "#fbba00",
      lightBlue: "#b6d9f2",
      green: "#5bb450",
      darkBlue: "#0862a1",
    },
  };

// Define the global styles
const styles = {
  global: {
    body: {
      bg: "#fafafa",
      fontFamily: "Inter, sans-serif",
    },
  },
};

// Define the fonts
const fonts = {
  heading: "Inter, sans-serif",
  body: "Inter, sans-serif",
};

// Define the configuration
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// Extend the theme with component styles
const theme = extendTheme({
    colors,
    styles,
    fonts,
    config,
    components: {
      Card: cardTheme,
      Tag: tagTheme,
      Button: {
        variants: {
          customGhost: {
            bg: "transparent",
            border: "none",
            borderColor: "transparent",
            padding: "0",
            _hover: {
              bg: "transparent",
            },
            _active: {
              bg: "transparent",
            },
            _focus: {
              boxShadow: "none",
            },
          },
        },
      },
    },
  });

export default theme;