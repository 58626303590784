import React, { useState } from "react";
import { Input, useToast } from "@chakra-ui/react";
import ContinueButton from "../components/Buttons/ContinueButton";
import BackButton from "../components/Buttons/BackButton";
import ParrotText from "../components/ParrotText";

interface EmailInputProps {
  onEmailSubmit: (email: string) => void;
  onNameReset: () => void;
}

const EmailInput: React.FC<EmailInputProps> = ({ onEmailSubmit, onNameReset }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();

  const handleContinue = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      onEmailSubmit(email);
    } else {
      setError(true);
      toast({
        title: "Invalid email",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleBack = () => {
    onNameReset();
  };

  return (
    <div className="flex flex-col h-full w-full overflow-hidden items-center justify-between">
      <div className="w-full">
        <div className="w-full flex justify-start">
          <BackButton handleBack={handleBack} />
        </div>
        <div className="w-full pt-12">
          <ParrotText />
        </div>
      </div>
      <div className="w-3/4">
        <Input
          value={email}
          onChange={handleInputChange}
          focusBorderColor="brand.blue"
          borderColor={error ? "red.500" : "gray.300"}
          placeholder="Enter your email here"
          background="white"
          width="full"
        />
      </div>
      <div className="w-full p-2 pb-16">
        <ContinueButton onClick={handleContinue} />
      </div>
    </div>
  );
};

export default EmailInput;