import React from "react";

interface StatDiffProps {
  className?: string;
  value: number;
  postText?: string;
}

const StatDiff: React.FC<StatDiffProps> = ({ className, value, postText }) => {
  return (
    <>
      {value != 0 && (
        <div
          className={`
            min-w-8
            max-w-fit
            p-1
            h-8
            rounded-full
            flex
            items-center
            justify-center
            text-[var(--color-green)]
            text-sm
            drop-shadow-lg
            bg-white
            ${className ? className : ""}
            `}
        >
          {(value > 0 ? `+${value}` : `${value}`) + (postText || "")}
        </div>
      )}
    </>
  );
};

export default StatDiff;
