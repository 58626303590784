import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem, Button, Flex } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Title from "../Title";

interface CourseMenuProps {
  title: string;
  onSelectCourse: (courseId: number) => void;
}

const CourseMenu: React.FC<CourseMenuProps> = ({ title, onSelectCourse }) => {
  const nav = useNavigate();

  return (
    <Menu matchWidth={true}>
      <MenuButton as={Button} variant={"customGhost"}>
        <Flex align="center">
          <Title text={title} />
          <ChevronDownIcon ml={2} />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => onSelectCourse(1)} justifyContent={"center"}>🇪🇸 General Spanish 1</MenuItem>
        <MenuItem onClick={() => nav("/construction")} justifyContent={"center"}>✈️ Travel Essentials</MenuItem>
        <MenuItem onClick={() => nav("/construction")} justifyContent={"center"}>🎶 Music and Entertainment</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CourseMenu;