import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    bg: '#DADADA',
    color: '#979797',
    fontSize: '10px',
    borderRadius: 'md',
    px: '11px',
    py: '6px',
  },
})

export const tagTheme = defineMultiStyleConfig({ baseStyle })
