import React from "react";
import { useNavigate } from "react-router-dom";
import { SimpleGrid, Tag } from "@chakra-ui/react";
import { ES, GB } from "country-flag-icons/react/3x2";
import logo from "../../assets/ParrotLogo.png";
import { UserWord } from "../Types";
import { getKnownWordsDictionary, getLearningWordsDictionary, callWithAuth } from "../../api/Api";
import { useQuery } from "@tanstack/react-query";

interface WordListViewProps {
  type: "Known" | "Learning";
  onWordSelect: (word : UserWord) => void;
}

const WordListView: React.FC<WordListViewProps> = ({ type }) => {

  const knownWordsQuery = useQuery({
    queryKey: ["knownWords"],
    queryFn: () => {
      return callWithAuth(getKnownWordsDictionary);
    },
  });

  const learningWordsQuery = useQuery({
    queryKey: ["learningWords"],
    queryFn: () => {
      return callWithAuth(getLearningWordsDictionary);
    },
  });

  const knownWords = knownWordsQuery.data?.knownWords.map((word: UserWord) => ({
    userWordId: word.userWordId,
    targetWord: word.targetWord,
    nativeWord: word.nativeWord,
    wordKey: word.wordKey,
  })) || [];

  const learningWords = learningWordsQuery.data?.learningWords.map((word: UserWord) => ({
    userWordId: word.userWordId,
    targetWord: word.targetWord,
    nativeWord: word.nativeWord,
    wordKey: word.wordKey,
  })) || [];

  const wordArray = type === "Known" ? knownWords : learningWords;
  const nav = useNavigate();

  return (
    <SimpleGrid
      columns={3}
      spacingX={3}
      spacingY={6}
      bg={"white"}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
      justifyItems={"center"}
      p={4}
      rounded={"xl"}
    >
      <ES title="Spain" className="w-8" />
      <GB title="GB" className="w-8" />
      <img src={logo} alt="Parrot Logo" className="w-7" />
      { wordArray.map((word, index) => (
        <React.Fragment key={index}>
          <div className="font-bold">{word.targetWord}</div>
          <div>{word.nativeWord}</div>
          <button onClick={() => nav('/construction')}>
            <Tag>more</Tag>
          </button>
        </React.Fragment>
      ))}
    </SimpleGrid>
  );
};

export default WordListView;