import React, { useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Header from "../components/HomeHeader/Header";
import NavBar from "../components/NavBar/NavBar";

type ActivePage = "learn" | "dictionary" | "profile";

const HomeLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0); // Add state for activeTab

  const getActivePage = (): ActivePage => {
    const path = location.pathname.split("/").pop();
    if (path === "learn" || path === "dictionary" || path === "profile") {
      return path;
    }
    return "learn";
  };

  const activePage = getActivePage();
  const tabNames = ["Learn", "Grow"];

  return (
    <div className="appPadding flex flex-col h-full w-full">
      <div className="fixed top-0 left-0 w-full h-16 items-center z-10 bg-inherit">
        <Header
          activePage={activePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabNames={tabNames}
        />
      </div>
      <div className="flex flex-col justify-top w-full pb-24">
        <Outlet context={{ activeTab, setActiveTab }} />
      </div>
      <nav className="fixed bottom-0 left-0 w-full z-10">
        <NavBar navigate={navigate} activePage={activePage} />
      </nav>
    </div>
  );
};

export default HomeLayout;
