import React from "react";
import logo from "../assets/ParrotLogo.png";
import ParrotText from "./ParrotText";

const LogoAndText: React.FC = () => {
  return (
    <div className="w-full flex flex-col items-center gap-1">
      <img src={logo} alt="Parrot Logo" className="w-32 h-auto"/>
      <ParrotText />
    </div>
  );
};

export default LogoAndText;