import React from "react";
import {
  FormControl,
  InputRightElement,
  InputGroup,
  Stack,
  Text,
  Input,
  Button,
  // Divider,
  Link,
  // AbsoluteCenter,
  // Box,
} from "@chakra-ui/react";
import BackButton from "@/components/Buttons/BackButton";
// import GoogleButton from "@/components/Buttons/GoogleButton";
import ParrotText from "@/components/ParrotText";
import { useToast } from "@chakra-ui/react";
import {
  axiosInstance,
  GetUserInfoResponse,
  // postNewUser,
  // callWithAuth,
} from "@/api/Api";
import { Navigate, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { tokenStorage } from "@/api/tokenStorage";
import { signInUser } from "@/api/login";

const LoginPage: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [show, setShow] = React.useState(false);
  // const [showGoogleAuthLoading, setGoogleAuthLoading] = React.useState(false);
  const userInfoQuery = useQuery({
    queryKey: ["AuthQueryLoginPage"],
    retry: 1,
    queryFn: async () => {
      const authToken = tokenStorage.getToken();
      const response = await axiosInstance.get<GetUserInfoResponse>(
        "/auth/userinfo",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response.data;
    },
  });
  const userInfo: GetUserInfoResponse | undefined =
    userInfoQuery.data || undefined;
  const toast = useToast();

  const nav = useNavigate();
  const shouldRedirect = userInfo != undefined && !userInfo.isTemp;
  const isPageLoading = tokenStorage.isTokenSet() && userInfoQuery.isPending;
  // TODO -> make a pretty loading screen

  // TODO MAKE THIS LOADING PAHE COMPONENT WITH A MESSAGE PRETTY
  // if (showGoogleAuthLoading) return "Continue with Google in the other window.";
  // TODO -> make a pretty loading screen
  if (isPageLoading) return "Loading...";

  if (shouldRedirect) {
    return <Navigate to="/home/learn" />;
  }

  const handleLogin = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === "") {
      toast({
        title: "Email required",
        description: "Please enter an email address",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else if (!emailRegex.test(email)) {
      toast({
        title: "Invalid email format",
        description: "Please enter a valid email address",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else if (password == "") {
      toast({
        title: "Password required",
        description: "Please enter you password.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        await signInUser(email, password);
        nav("/home/learn");
      } catch (err) {
        let uiErrMsg;
        if ((err as any).response.code == 400) {
          uiErrMsg = "Please check your email and password";
        }
        if (!uiErrMsg) {
          uiErrMsg = "Sign-in failed please try again.";
        }
        toast({
          title: "Sign-in failed",
          description: `${uiErrMsg}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  {
    /*const handleGoogleLogin = async () => {
    try {
      setGoogleAuthLoading(true);
      if (userInfo && userInfo.isTemp) {
        // TEMP USER SHOULD NOT BE HERE,
        // they should have been redirected. we will error here
        throw new Error("temp user tried to sign in with google");
      } else {
        const res = await signInWithGoogle();
        const createdAt = res.record.created;
        const withinSeconds = 5;
        if (wasJustCreated(createdAt, withinSeconds)) {
          // create the new user and redirect
          // to onboarding
          await callWithAuth(postNewUser);
          nav("/onboarding");
        } else {
          // means the user had an account and probably did onboarding already
          nav("/home/learn");
        }
      }
    } catch (err) {
      setGoogleAuthLoading(false);
      let uiErrMsg;
      if (!uiErrMsg) {
        uiErrMsg = "Sign-in failed please try again.";
      }
      toast({
        title: "Sign-in failed",
        description: `${uiErrMsg}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };*/
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePassswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);
  };

  return (
    <Stack
      spacing={6}
      width={"100%"}
      height={"100%"}
      justifyContent={"space-between"}
      align={"center"}
      overflow={"hidden"}
      className="appPadding overflow-hidden"
    >
      <div className="self-start">
        <BackButton
          handleBack={() => {
            nav("/");
          }}
        />
      </div>
      <Stack align="center" spacing={10} width={"100%"}>
        <ParrotText />
        <Stack align="center" spacing={0}>
          <div className="text-black text-center text-xl font-semibold mb-2 mt-2">
            Log in to your account
          </div>
          <div className="text-sm">Continue where you left off!</div>
        </Stack>
      </Stack>

      <Stack
        align="center"
        spacing={6}
        width={"100%"}
        bg={"white"}
        padding={4}
        paddingY={6}
        rounded={"2xl"}
      >
        <Stack spacing={4} width={"100%"}>
          <Input
            value={email}
            onChange={handleInputChange}
            placeholder="Email"
          />
          <FormControl isRequired>
            <InputGroup size="md">
              <Input
                value={password}
                type={show ? "text" : "password"}
                isRequired
                placeholder="Password"
                onChange={handlePassswordChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
            type="button"
            background={"var(--color-blue)"}
            textColor={"white"}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Text fontSize={"sm"}>
            Don't have an account?{" "}
            <Link href="/signup" color="var(--color-blue)">
              Sign up
            </Link>
          </Text>
        </Stack>

        {/*<Box width={"100%"} position={"relative"}>
          <Divider />
          <AbsoluteCenter
            bg="white"
            px="4"
            textColor={"gray.500"}
            fontSize={"sm"}
          >
            OR
          </AbsoluteCenter>
        </Box>

        <Stack spacing={4} width={"100%"}>
          <GoogleButton onClick={handleGoogleLogin} />
        </Stack>*/}
      </Stack>
      <div className="w-full min-h-10"></div>
    </Stack>
  );
};

{/*function wasJustCreated(timestamp: string, seconds: number): boolean {
  const currentTime = new Date().getTime(); // Current time in milliseconds
  const inputTime = new Date(timestamp).getTime(); // Input timestamp in milliseconds

  const differenceInSeconds = (currentTime - inputTime) / 1000; // Difference in seconds

  return differenceInSeconds <= seconds;
}*/}

export default LoginPage;
