import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "../../Animations.css";
import CardList from "./CardList";

export interface Indexes {
  previousIndex: number;
  currentIndex: number;
  nextIndex: number;
}

interface CardCarouselProps {
  cardItems: CardItem[];
}

interface CardItem {
  id: number;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const CardCarousel: React.FC<CardCarouselProps> = ({ cardItems }) => {
  const [indexes, setIndexes] = useState<Indexes>({
    previousIndex: 0,
    currentIndex: 0,
    nextIndex: 1,
  });

  const handleCardTransition = () => {
    if (indexes.currentIndex >= cardItems.length - 1) {
      setIndexes({
        previousIndex: cardItems.length - 1,
        currentIndex: 0,
        nextIndex: 1,
      });
    } else {
      setIndexes((prevState) => ({
        previousIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex + 1,
        nextIndex:
          prevState.currentIndex + 2 === cardItems.length
            ? 0
            : prevState.currentIndex + 2,
      }));
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: handleCardTransition,
    trackMouse: true,
  });

  return (
    <div className="w-full px-1 z-0 overflow-hidden touch-none" {...swipeHandlers} onClick={handleCardTransition} >
      <CardList cardItems={cardItems} indexes={indexes} />
    </div>
  );
};

export default CardCarousel;