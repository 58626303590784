import { StatBlock, TimeStatBlock } from "@/components/StatBlock";
import React from "react";
import InfoBox from "@/components/InfoBox";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { getUserStats, callWithAuth } from "@/api/Api";
import { useQuery } from "@tanstack/react-query";
import { LessonStats } from "@/pages/UseLessonStats";
import { USER_STATS_QUERY_KEY } from "@/query_keys";

interface SignUpPromptProps {
  buttonAction: () => void;
  lessonStats: LessonStats;
}

const SignUpPrompt: React.FC<SignUpPromptProps> = ({
  buttonAction,
  lessonStats,
}) => {
  const userStatsQuery = useQuery({
    queryKey: [USER_STATS_QUERY_KEY],
    queryFn: () => {
      return callWithAuth(getUserStats);
    },
  });

  const listeningTime = userStatsQuery.data?.listeningTime || 0;
  const wordsKnownCount = userStatsQuery.data?.wordsKnown || 0;
  const waterDropletsCount = userStatsQuery.data?.waterDropletsCount || 0;

  return (
    <div className="appPadding flex flex-col items-center justify-between w-full h-full overflow-hidden gap-8">
      <div className="p-2 flex flex-row justify-center items-center w-full gap-2 pt-8 pb-4">
        <TimeStatBlock
          timeSeconds={listeningTime}
          preText="⏰ "
          statDiff={lessonStats.listenTimeAdded}
        />
        <StatBlock
          value={wordsKnownCount}
          preText="🇪🇸 "
          subtitle="Known Words"
          statDiff={lessonStats.knownWordsAdded}
        />
        <StatBlock
          value={waterDropletsCount}
          preText="💧 "
          subtitle="Water Drops"
          statDiff={lessonStats.dropsAdded}
        />
      </div>
      <div className="flex-grow flex w-full items-center justify-center">
        <InfoBox
          title={
            <>
              <p className="px-2">
                Sign Up or Lose Your{" "}
                <span className="font-bold text-[var(--color-blue)]">
                  Progress
                </span>
              </p>
            </>
          }
          subtitle={
            <div className="flex flex-col gap-4 items-start text-start">
              <span>
                In <span className="font-bold">2 months and 14 days</span>, you
                can have basic conversations with native speakers at your
                current rate!
              </span>
              <div className="flex flex-col gap-1 text-sm">
                <span>💳 100% Free. No Credit Card.</span>
                <span>🌳 Your trees won’t die</span>
                <span>📲 Save your hard earned progress</span>
              </div>
            </div>
          }
        />
      </div>
      <div className="w-full pb-2">
        <ContinueButton onClick={buttonAction} text="Sign Up" />
      </div>
    </div>
  );
};

export default SignUpPrompt;
