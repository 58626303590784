import React from "react";
import { useQuery } from "@tanstack/react-query";
import { callWithAuth, getStatsTable, GetStatsTableRequest } from "@/api/Api";
import { CartesianGrid, Line, LineChart, XAxis, YAxis, Legend } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

const chartConfig = {
  drops: {
    label: "drops",
    color: "hsl(var(--chart-1))",
  }
} satisfies ChartConfig;

const renderCustomLegend = (props: any) => {
  const { payload } = props;
  return (
    <ul style={{ display: 'flex', justifyContent: 'start', paddingTop: 0, paddingBottom: 18, paddingLeft: 24 }}>
      {
        payload.map((entry: any, index: number) => (
          <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
            <svg width="10" height="10" viewBox="0 0 32 32" style={{ marginRight: 6 }}>
              <circle cx="14" cy="14" r="14" fill={entry.color} />
            </svg>
            <span style={{ display: 'flex', alignItems: 'center' }}>Water Droplets</span>
          </li>
        ))
      }
    </ul>
  );
};

const DropsChart: React.FC = () => {

  const query = useQuery({
    queryKey: ["statsTable"],
    queryFn: () => {
      const data: GetStatsTableRequest = {
        tableType: "water_droplets",
      };
      return callWithAuth(getStatsTable, data);
    },
  });

  const chartData = query.data?.data || [];

  return (
    <ChartContainer config={chartConfig} className="w-full h-[200px] bg-white p-2 pr-4">
      <LineChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: 0,
          right: 16,
          top: 8,
          bottom: 8,
        }}
        height={200}
      >
        <CartesianGrid vertical={true}/>
        <XAxis
          dataKey="day"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickMargin={4}
          spacing={16}
          tickFormatter={(value) => value}
          interval={0}
          //domain={[0, Math.max(...chartData.map(data => data.value)) + 100]}
          //tickCount={Math.ceil(chartData[chartData.length - 1].value / 100) + 2}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Line
          dataKey="drops"
          type="linear"
          stroke="var(--color-blue)"
          strokeWidth={2}
          dot={{
            fill: "var(--color-blue)",
          }}
          activeDot={{
            r: 6,
          }}
        />
        <Legend 
          layout="horizontal" 
          verticalAlign="top" 
          align="center" 
          content={renderCustomLegend}
        />
      </LineChart>
    </ChartContainer>
  );
};

export default DropsChart;
