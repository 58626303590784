import React, { useState } from "react";
import {
  useParams,
  useNavigate,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import LessonHeader from "../components/Activities/LessonHeader";
import MCQ from "../components/Activities/MCQ";
import Match from "../components/Activities/Match";
import Write from "../components/Activities/Write";
import Fill from "../components/Activities/Fill";
// import Speak from "../components/Activities/Speak";
import { useLessonStats } from "./UseLessonStats";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { useQueryClient } from "@tanstack/react-query";
import VideoPage from "./VideoPage";
import {
  getLessonData,
  callWithAuth,
  GetLessonDataRequest,
  GetLessonDataResponse,
  postActivityComplete,
  PostActivityCompleteRequest,
  getUserInfo,
} from "../api/Api";
import { useQuery } from "@tanstack/react-query";
import {
  LESSON_DATA_QUERY_KEY,
  WATER_DROPLETS_QUERY_KEY,
  USER_STATS_QUERY_KEY,
} from "@/query_keys";
import { Dialogue } from "@/components/Types";
import ModuleComplete from "@/components/Motivation/ModuleComplete";
import correctSound from "../assets/correct_sound.wav"; // Import the sound file

interface ViewRoute {
  viewName: string;
  viewComponent: JSX.Element | null;
}

const MOTIVATION_PAGE = "yayy";

const Lesson: React.FC = () => {
  const [animate, setAnimate] = useState(false);
  const { chapterId, lessonId } = useParams();
  const lessonIdNumber = Number(parseInt(lessonId || "0"));
  const chapterIdNumber = Number(parseInt(chapterId || "0"));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userInfoQuery = useQuery({
    queryKey: ["userInfo"],
    queryFn: () => {
      return callWithAuth(getUserInfo);
    },
  });
  const isTempUser = userInfoQuery.data?.isTemp;

  const lessQKey = LESSON_DATA_QUERY_KEY + lessonId;
  const lessonDataQuery = useQuery({
    queryKey: [lessQKey],
    queryFn: () => {
      const data: GetLessonDataRequest = {
        lessonId: lessonIdNumber,
      };
      return callWithAuth(getLessonData, data);
    },
  });
  const location = useLocation(); // Hook to get the current location
  const { getStats, handleAddStatValue } = useLessonStats(
    chapterId || "",
    lessonId || "",
  );

  const nextLessonId = lessonDataQuery.data?.nextLessonId;
  const lesson = lessonDataQuery.data?.lesson;
  const isLastLesson = lessonDataQuery.data?.isLastLesson;
  const isLastChapter = lessonDataQuery.data?.isLastChapter;
  const videoActivity = lessonDataQuery.data?.video;
  const guessActivity = lessonDataQuery.data?.guess;
  const matchActivity = lessonDataQuery.data?.match;
  const fillActivity = lessonDataQuery.data?.fill;
  const navigateToChapter = useNavigateUp();
  const writeActivity = lessonDataQuery.data?.write;

  const handleUpdateDialogues = (ds: Dialogue[]) => {
    // this should re render
    queryClient.setQueryData([lessQKey], (oldData: GetLessonDataResponse) => {
      const newData: GetLessonDataResponse = {
        ...oldData,
        video: { ...oldData.video, dialogues: ds },
      };
      return newData;
    });
  };
  // Handlers for each stat
  const handleAddDrops = (amount: number) => {
    handleAddStatValue("dropsAdded", amount);

    if (amount > 0) {
      setAnimate(true);
      queryClient.invalidateQueries({ queryKey: [WATER_DROPLETS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [USER_STATS_QUERY_KEY] });
      setTimeout(() => setAnimate(false), 500);
    }
  };

  const handleAddTime = (time: number) => {
    handleAddStatValue("listenTimeAdded", time);
  };

  const handleAddKnownWords = (amount: number) => {
    handleAddStatValue("knownWordsAdded", amount);
  };

  const handleAddLearningWords = (amount: number) => {
    handleAddStatValue("learningWordsAdded", amount);
  };

  const getCurrentActivityFromPath = () => {
    const pathParts = location.pathname.split("/");
    return pathParts[pathParts.length - 1]; // Get the last part of the path, e.g., 'video', 'guess'
  };

  const currentViewName = getCurrentActivityFromPath();

  const handlers = useSwipeable({
    onSwipedUp: () => goToNextView(),
    trackMouse: true,
    delta: 100,
  });

  const handleActivityFinished = () => {
    if (currentViewName !== "video") {
      const audio = new Audio(correctSound);
      audio.volume = 0.5;
      audio.play();
    }

    const activityName = currentViewName;
    const data: PostActivityCompleteRequest = {
      lessonId: lessonIdNumber,
      activityType: activityName,
    };
    // not handlign error here... do we care? not a critical error
    callWithAuth(postActivityComplete, data).then((res) => {
      // reward droplets
      handleAddDrops(res.waterDropletsAwarded);
    });

    const delayBetweenActivitiesMs = 1000;
    setTimeout(() => {
      goToNextView();
    }, delayBetweenActivitiesMs);
  };

  if (!lesson) {
    return <div>Loading...</div>;
  }

  let viewRoutes: ViewRoute[];

  const goToNextView = () => {
    const currentIndex = viewRoutes.findIndex(
      (route) => route.viewName === currentViewName,
    );
    if (currentViewName === MOTIVATION_PAGE) {
      if (nextLessonId == 0) {
        navigate("/home/learn");
        return;
      }
      navigate(`/chapter/${chapterId}/lesson/${nextLessonId}`);
      return;
    } else if (currentIndex >= 0 && currentIndex < viewRoutes.length - 1) {
      navigate(
        `/chapter/${chapterId}/lesson/${lessonId}/${viewRoutes[currentIndex + 1].viewName}`,
      );
      return;
    } else {
      // when your done a chapter navigate to home learn
      navigate("/home/learn");
      return;
    }
  };

  const goToPrevView = () => {
    const currentIndex = viewRoutes.findIndex(
      (route) => route.viewName === currentViewName,
    );
    if (currentIndex > 0 && currentIndex <= viewRoutes.length - 1) {
      navigate(
        `/chapter/${chapterId}/lesson/${lessonId}/${viewRoutes[currentIndex - 1].viewName}`,
      );
    } else {
      navigateToChapter();
    }
  };

  if (!chapterId && !lessonId) return <Navigate to="/home/learn" />;

  // Define views with their corresponding component
  viewRoutes = [
    {
      viewName: "video",
      viewComponent: videoActivity ? (
        <VideoPage
          chapterId={parseInt(chapterId || "0")}
          lesson={lesson}
          videoActivity={videoActivity!}
          onActivityFinished={handleActivityFinished}
          onUpdateDialogues={handleUpdateDialogues}
          onTrackDropsAdded={handleAddDrops}
          onTrackKnownAdded={handleAddKnownWords}
          onTrackLearningAdded={handleAddLearningWords}
          onTrackListeningTime={handleAddTime}
        />
      ) : null,
    },
    {
      viewName: "guess",
      viewComponent: guessActivity ? (
        <MCQ
          guessActivity={guessActivity}
          lessonId={lessonIdNumber}
          onActivityFinished={handleActivityFinished}
        />
      ) : null,
    },
    {
      viewName: "match",
      viewComponent: matchActivity ? (
        <Match
          matchActivity={matchActivity}
          onActivityFinished={handleActivityFinished}
          lessonId={lessonIdNumber}
        />
      ) : null,
    },
    {
      viewName: "fill",
      viewComponent: fillActivity ? (
        <Fill
          fillInTheBlankActivity={fillActivity}
          onActivityFinished={handleActivityFinished}
          lessonId={lessonIdNumber}
          onTrackListeningTime={handleAddTime}
        />
      ) : null,
    },
    {
      viewName: "write",
      viewComponent: writeActivity ? (
        <Write
          lessonId={lessonIdNumber}
          writeActivity={writeActivity}
          onActivityFinished={handleActivityFinished}
        />
      ) : null,
    },
    {
      viewName: MOTIVATION_PAGE,
      viewComponent: (
        <ModuleComplete
          onAddDroplets={handleAddDrops}
          nextLessonId={nextLessonId || 0}
          isTempUser={isTempUser!}
          isLastChapter={isLastChapter || false}
          isLastLesson={isLastLesson || false}
          lessonStats={getStats()}
          lessonId={lessonIdNumber}
          lesson={lesson}
          chapterId={chapterIdNumber}
        />
      ),
    },
  ].filter((route) => route.viewComponent); // Filter out null components

  if (viewRoutes.length == 0) {
    // display some error about bad lesson?
    // handle this error gracefully
    // TODO
    // Ideas.
    // 1. show error toast
    // 2. redirect to error page with contact support button
    // 3. do nothing.
  }

  const firstActivity = viewRoutes[0].viewName;

  return (
    <div
      {...handlers}
      className="flex flex-col h-full w-full justify-between items-center overflow-hidden"
    >
      <LessonHeader handleBack={goToPrevView} pulse={animate} />
      <main className="flex-grow flex w-full items-center justify-center">
        <Routes>
          {/* Redirect from base URL (e.g. /chapter/1/lesson/7) to the first activity */}
          <Route path="" element={<Navigate to={firstActivity} />} />

          {/* Dynamically render routes */}
          {viewRoutes.map(({ viewName, viewComponent }) => (
            <Route key={viewName} path={viewName} element={viewComponent} />
          ))}
        </Routes>
      </main>
      <footer className="flex flex-col items-center justify-center pb-6">
        <MdKeyboardDoubleArrowUp className="w-6 h-auto" />
        <span className="text-[9px]">Swipe up to skip</span>
        <div className="text-base text-[var(--color-orange)] font-extrabold pt-3 font-[Poppins]">
          parrot®
        </div>
      </footer>
    </div>
  );
};

// Custom hook to navigate up one level
const useNavigateUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to navigate up one level in the URL hierarchy
  const navigateToChapter = () => {
    const pathParts = location.pathname.split("/"); // Split the URL by "/"
    const chapterPath = pathParts.slice(0, -3); // Remove the last part of the URL (e.g., "lesson/7/video")
    const newPath = chapterPath.join("/"); // Join the remaining parts back together
    navigate(newPath); // Navigate to the new path
  };

  return navigateToChapter;
};

export default Lesson;
