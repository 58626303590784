import React from "react";
import ParrotText from "../components/ParrotText";
import ContinueButton from "../components/Buttons/ContinueButton";
import InfoBox from "../components/InfoBox";
import BackButton from "@/components/Buttons/BackButton";

const NamePage: React.FC = () => {
  const handleContinue = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSeKbSJPqRwGyRr4ghmsPiGQ0kZIoG3UFEdLd3eQaXxOAbBRjw/viewform?usp=sf_link", "_blank");
  };

  return (
    <div className="appPadding flex flex-col h-full w-full overflow-hidden items-center justify-between">
      <div className="self-start mt-8">
      <BackButton handleBack={() => window.history.back()} />
      </div>
      <div className="w-full pt-12">
        <ParrotText />
      </div>
      <div className="w-full flex flex-grow justify-center">
        <InfoBox
          title={
            <>
            <p>This Feature is Under Construction</p>
            <p>🚧 🚧 🚧</p>
          </>
          }
          subtitle="We're currently working on making this feature as amazing as possible for you! We would love to hear your suggestions on what you would like to see. Contact us using the button below. "
        />
      </div>
      <div className="w-full pb-16">
        <ContinueButton onClick={handleContinue} text="Leave Feedback" />
      </div>
    </div>
  );
};

export default NamePage;