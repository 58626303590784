import React, { useState, useRef } from "react";
import { Button } from "./Button";
import "../../Animations.css";

interface ActivityButtonProps {
  text: string;
  isCorrect: boolean;
  onClick?: () => void;
}

const getBackgroundColor = (color: string) => {
  if (color == "black") return "";
  if (color === "red") return "var(--color-red)";
  if (color === "green") return "var(--color-green)";
  return "";
};

const ActivityButton: React.FC<ActivityButtonProps> = ({
  text,
  isCorrect,
  onClick,
}) => {
  const [color, setColor] = useState<"black" | "green" | "red">("black");
  const shake = color == "red";
  const lastPressTime = useRef<number>(0);
  const debounceTime = 300; // Set debounce time in milliseconds

  const handleClick = () => {
    const currentTime = Date.now();

    // Check if the time between clicks is less than the debounce time
    if (currentTime - lastPressTime.current < debounceTime) {
      return; // Ignore the click
    }

    lastPressTime.current = currentTime;
    if (isCorrect) {
      setColor("green");
    } else {
      setColor("red");
    }
    if (!isCorrect) {
      setTimeout(() => {
        setColor("black");
      }, 400);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant="default"
      onClick={handleClick}
      className={shake ? "strong-hover-shake" : ""}
      style={{
        backgroundColor: getBackgroundColor(color),
        color: "white",
      }}
    >
      {text}
    </Button>
  );
};

export default ActivityButton;
