import React from "react";
import { PiDropFill } from "react-icons/pi";
import { TbProgressCheck } from "react-icons/tb";
import { FaLock } from "react-icons/fa6";

interface LessonIconProps {
    status: "completed" | "current" | "locked";
}

const statusConfig = {
    completed: {
        bgColor: "bg-[#0C92F154]",
        icon: <PiDropFill className="text-[var(--color-blue)] w-6 h-6" />
    },
    current: {
        bgColor: "bg-[#F7CBA8]",
        icon: <TbProgressCheck className="text-[var(--color-orange)] w-6 h-6" />
    },
    locked: {
        bgColor: "bg-[#DADADA]",
        icon: <FaLock className="text-[#979797] w-5 h-5" />
    }
};

const LessonIcon: React.FC<LessonIconProps> = ({ status }) => {
    const { bgColor, icon } = statusConfig[status];

    return (
        <div className={`flex items-center justify-center w-9 h-9 rounded-lg ${bgColor}`}>
          {icon}
        </div>
    );
};

export default LessonIcon;