import React from "react";
import ChatButton from "../Buttons/ChatButton";

interface ChatOptionsProps {
  options: string[];
  onOptionClick: (option: string) => void;
}

const ChatOptions: React.FC<ChatOptionsProps> = ({ options, onOptionClick }) => {
  return (
    <div className="flex flex-col items-end gap-1">
      {options.map((option, index) => (
        <ChatButton key={index} text={option} onClick={() => onOptionClick(option)} />
      ))}
    </div>
  );
};

export default ChatOptions;