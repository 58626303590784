import React from "react";
import { Card } from "@chakra-ui/react";

interface DictionaryHeaderProps {
  wordsKnown: number;
  wordsLearning: number;
  onLearningClick: () => void;
  onKnownClick: () => void;
}

const DictionaryHeader: React.FC<DictionaryHeaderProps> = ({
  wordsKnown,
  wordsLearning,
  onLearningClick,
  onKnownClick,
}) => {
  return (
    <Card
      direction={"row"}
      align={"center"}
      className="w-full h-auto justify-evenly"
    >
      <div
        onClick={onLearningClick}
        className="flex flex-col items-center justify-center gap-2 text-[var(--color-orange)]"
      >
        <div className="text-2xl font-semibold">{wordsLearning}</div>
        <div className="text-base font-normal">Learning</div>
      </div>
      <div
        onClick={onKnownClick}
        className="flex flex-col items-center justify-center gap-2 text-[var(--color-blue)]"
      >
        <div className="text-2xl font-semibold">{wordsKnown}</div>
        <div className="text-base font-normal">Known</div>
      </div>
    </Card>
  );
};

export default DictionaryHeader;
