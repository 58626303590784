import React from "react";
import clickAnimation from "../../assets/ClickAnimation.json";
import Lottie from "lottie-react";
import Subtitle from "./Subtitle";
import Translation from "./Translation";
import { Dialogue, UserVideoWord } from "../Types";
import AddWordButton from "../Buttons/AddWordButton";

interface VideoContentProps {
  currentTime: number;
  dialogues: Dialogue[];
  onWordClick: (clickedWord: UserVideoWord) => void;
  onKnownClick: (word: UserVideoWord) => void;
  displayHelp: boolean;
  targetWord: string;
  nativeWord: string;
  selectedWordStatus: string;
}

const VideoContent: React.FC<VideoContentProps> = ({
  currentTime,
  dialogues,
  onWordClick,
  onKnownClick,
  displayHelp,
  targetWord,
  nativeWord,
  selectedWordStatus,
}) => {
  // we need to get the word here.
  // we can get that from highlight index
  // This component will rerender whene ver the curretTimeState changes in the parents
  // current time state drives each render we need to recalc all of them
  // all the display for this component is derived from the parent time state
  const dialogueIndex = calcDisplayIndex(currentTime, dialogues);
  const userVideoWords = dialogues[dialogueIndex].userVideoWords;
  const highlightIndex = calcDisplayIndex(currentTime, userVideoWords);
  const isKnown = selectedWordStatus == "known";

  const handleKnownClick = () => {
    const word = userVideoWords[highlightIndex];
    onKnownClick(word);
  };

  return (
    <div className="max-h-80 relative flex flex-col justify-start w-full rounded-xl bg-white px-4 py-2 sm:p-2 flex-grow">
      <Subtitle
        highlightIndex={highlightIndex}
        userVideoWords={userVideoWords}
        onWordClick={onWordClick}
        onDoubleWordClick={handleKnownClick}
      />
      {displayHelp ? (
        <div className="flex flex-row text-center justify-center items-center pt-2">
          {/* this div is a spacer made to be the same size of the flag */}
          <Lottie
            animationData={clickAnimation}
            loop={true}
            className="h-14 w-14"
          />
          <div className="font-semibold ">
            Try clicking the words!
          </div>
        </div>
      ) : (
        <div className="pt-4">
          <Translation targetWord={targetWord} nativeWord={nativeWord} />
        </div>
      )}
      {!displayHelp && (
        <div className="mt-auto pt-2 flex flex-row-reverse">
          <AddWordButton isKnown={isKnown} onClick={handleKnownClick} />
        </div>
      )}
    </div>
  );
};

export default VideoContent;

type ArrWithStartTimes = Dialogue | UserVideoWord;
// For a given time and array on this page, we can calcualte the index of
// a word or dialogue we want to show.
function calcDisplayIndex(time: number, arr: ArrWithStartTimes[]) {
  // return the first index where the word Or dialogue does not belong
  // minus one posittion. If the word doesnt belong in the zeroth position,
  // we make sure to return zero
  for (let i = 0; i < arr.length; i++) {
    const v = arr[i];
    if (time >= v.start) {
      continue;
    } else if (i == 0) {
      // our current time is before the first dialogue start, return the first dialogue
      return i;
    } else {
      // our current time is one greater
      return i - 1;
    }
  }
  // goes through the whole loop return end
  return arr.length - 1;
}
