import React from "react";

const ParrotText: React.FC = () => {
  return (
    <div className="text-center text-[var(--color-orange)] text-5xl font-extrabold font-[Poppins] break-words">
        parrot®
    </div>
  );
};

export default ParrotText;