import { useRef, useEffect } from "react";

export interface LessonStats {
  dropsAdded: number; // Number of drops added
  listenTimeAdded: number; // Total listening time added
  knownWordsAdded: number; // Number of known words added
  learningWordsAdded: number; // Number of learning words added
}
/**
 * Custom hook to manage lesson statistics using refs and localStorage with periodic saving and conditional clearing logic.
 * This hook:
 * - tracks the stats added in refs in 1 sessino. If the chpater or lessonId change it reset
 * Usage:
 * - When a stat changes, use `handleAddStatValue` to update the stat in the refs (not directly in localStorage).
 *
 * return Object containing the lesson stats, the event handler to update stat.
 */
export const useLessonStats = (chapterId: string, lessonId: string) => {
  // Use refs to store current values in memory
  const dropsAddedRef = useRef(0);
  const listenTimeAddedRef = useRef(0);
  const knownWordsAddedRef = useRef(0);
  const learningWordsAddedRef = useRef(0);
  // Function to handle stat changes by adding to the current ref value
  const handleAddStatValue = (statKey: keyof LessonStats, toAdd: number) => {
    switch (statKey) {
      case "dropsAdded":
        dropsAddedRef.current += toAdd;
        break;
      case "listenTimeAdded":
        listenTimeAddedRef.current += toAdd;
        break;
      case "knownWordsAdded":
        knownWordsAddedRef.current += toAdd;
        break;
      case "learningWordsAdded":
        learningWordsAddedRef.current += toAdd;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dropsAddedRef.current = 0;
    listenTimeAddedRef.current = 0;
    knownWordsAddedRef.current = 0;
    learningWordsAddedRef.current = 0;
  }, [lessonId, chapterId]);

  const getStats = () => ({
    dropsAdded: dropsAddedRef.current,
    listenTimeAdded: listenTimeAddedRef.current,
    knownWordsAdded: knownWordsAddedRef.current,
    learningWordsAdded: learningWordsAddedRef.current,
  });
  return {
    stats: getStats(),
    handleAddStatValue,
    getStats,
  };
};
