import React from "react";
import ProfileCard from "../components/ProfileCard";
import Title from "../components/Title";
import { SimpleGrid } from "@chakra-ui/react";
import { StatBlock, TimeStatBlock } from "../components/StatBlock";
import DropsChart from "../components/DropsChart";
import { useQuery } from "@tanstack/react-query";
import { getUserStats, callWithAuth, getUserInfo } from "../api/Api";
import LogoutButton from "@/components/Buttons/LogoutButton";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { useNavigate } from "react-router-dom";

const ProfilePage: React.FC = () => {
  const nav = useNavigate();
  const userStatsQuery = useQuery({
    queryKey: ["userStats"],
    queryFn: () => {
      return callWithAuth(getUserStats);
    },
  });

  const userInfoQuery = useQuery({
    queryKey: ["userInfo"],
    queryFn: () => {
      return callWithAuth(getUserInfo);
    },
  });

  const listeningTime = userStatsQuery.data?.listeningTime || 0;
  const wordsKnown = userStatsQuery.data?.wordsKnown || 0;
  const dayStreak = userStatsQuery.data?.dayStreak || 0;
  const waterDropletCount = userStatsQuery.data?.waterDropletsCount || 0;
  const treesPlanted = userStatsQuery.data?.treesPlanted || 0;
  const chaptersCompleted = userStatsQuery.data?.chaptersCompleted || 0;
  const level = userStatsQuery.data?.level || 0;

  const user = userInfoQuery.data || {
    id: 0,
    firstName: "",
    email: "",
    joinDate: "",
    isTemp: true,
    avatarUrl: "",
  };
  const isTemp = user?.isTemp;

  return (
    <div className="flex flex-col justify-start w-full h-full pt-20 gap-4">
      <ProfileCard level={level} user={user} />
      <Title text="Progress" />
      <SimpleGrid columns={2} spacing={3}>
        <TimeStatBlock timeSeconds={listeningTime} postText=" ⏰" />
        <StatBlock value={wordsKnown} postText=" 🇪🇸" subtitle="Words Added" />
        <StatBlock
          value={waterDropletCount}
          postText=" 💧"
          subtitle="Water Drops"
        />
        <StatBlock value={dayStreak} postText=" 🔥" subtitle="Day Streak" />
        <StatBlock
          value={treesPlanted}
          postText=" 🌳"
          subtitle="Trees Planted"
        />
        <StatBlock
          value={chaptersCompleted}
          postText=" 🍃"
          subtitle="Chapters Completed"
        />
      </SimpleGrid>
      <DropsChart />
      <div className="w-full">
        {isTemp ? (
          <ContinueButton
            onClick={() => {
              nav("/signup");
            }}
            text="Login/Signup"
          />
        ) : (
          <LogoutButton />
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
