import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../components/Video/VideoPlayer";
import VideoContent from "../components/Video/VideoContent";
import {
  Dialogue,
  UserVideoWord,
  UserWordStatus,
  VideoActivity,
} from "../components/Types";
import LessonBlock from "@/components/ChapterPath/LessonBlock";
import { Button } from "../components/Buttons/Button";
import { Lesson } from "../components/Types";
import { VideoPlayerHandle } from "../components/Video/VideoPlayer";
import { callWithAuth, putWordStatus, PutWordStatusRequest } from "../api/Api";

interface VideoPageProps {
  chapterId: number;
  lesson: Lesson;
  videoActivity: VideoActivity;
  onActivityFinished: () => void;
  onUpdateDialogues: (ds: Dialogue[]) => void;
  onTrackDropsAdded: (drops: number) => void;
  onTrackLearningAdded: (learningWords: number) => void;
  onTrackKnownAdded: (knownWords: number) => void;
  onTrackListeningTime: (time: number) => void;
}

const VideoPage: React.FC<VideoPageProps> = ({
  chapterId,
  lesson,
  videoActivity,
  onActivityFinished,
  onUpdateDialogues,
  onTrackDropsAdded,
  onTrackKnownAdded,
  onTrackLearningAdded,
  onTrackListeningTime,
}) => {
  const navigate = useNavigate();
  const [selectedWord, setSelectedWord] = useState({} as UserVideoWord);
  const targetWord = selectedWord?.displayText || "";
  const nativeWord = selectedWord?.definition?.nativeWord || "";
  const videoRef = useRef<VideoPlayerHandle>(null);
  const [displayHelp, setDisplayHelp] = useState(true);
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const startTime = videoActivity.start;
  const endTime = videoActivity.end;
  const youtubePrefix = "youtube.com/watch?v=";
  const url = youtubePrefix + videoActivity.youtubeId;
  const dialogues = videoActivity.dialogues;

  // prevents the flickering from updating currentTime
  // once wordClick is started
  const pauseHandleTimeUpdate = useRef(false);

  const handleWordClick = async (word: UserVideoWord) => {
    pauseHandleTimeUpdate.current = true;
    setSelectedWord(word);
    setCurrentTime(word.start);
    setDisplayHelp(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.seekTo(word.start);
    }
    if (word.status == "known") {
      return;
    }
    await updateDialogueWordStatus(word, "learning");
  };

  const handleKnownClick = async (word: UserVideoWord) => {
    let newStatus: UserWordStatus = "known";
    if (word.status == "known") {
      newStatus = "learning";
      // went to learning
      onTrackKnownAdded(-1);
    }
    await updateDialogueWordStatus(word, newStatus);
    const newWord: UserVideoWord = {
      ...word,
      status: newStatus,
    };
    setSelectedWord(newWord);
  };

  // updates the state for dialogues and for
  // the server
  const updateDialogueWordStatus = async (
    word: UserVideoWord,
    newStatus: UserWordStatus,
  ) => {
    const newDialogues = newDialoguesWithUpdatedStatus(word, newStatus);
    const oldDialogues = dialogues;
    try {
      const data: PutWordStatusRequest = {
        wordKey: word.wordKey,
        newStatus: newStatus,
        definitionId: word.definition.definitionId,
      };
      const res = await callWithAuth(putWordStatus, data);
      if (newStatus == "known") {
        onTrackKnownAdded(1);
      }
      onUpdateDialogues(newDialogues);
      onTrackDropsAdded(res.waterDropletsAwarded);
      // we know a learned word was added,
      if (res.waterDropletsAwarded != 0) {
        onTrackLearningAdded(1);
      }
      // for when you want to test the new error handling we will
      // eventually have
      //throw new Error("test");
    } catch (e) {
      // TODO, handle this error better
      // we failed to put word status,
      // revert the UI state,
      // display some kind of message to user.
      // or simply we could try again one day that would be better
      // we should have the global error UI method defined
      // revert status
      console.error(e);
      onUpdateDialogues(oldDialogues);
      throw Error();
    }
  };
  // updates the stats of the specific dialogue
  const newDialoguesWithUpdatedStatus = (
    word: UserVideoWord,
    newStatus: UserWordStatus,
  ) => {
    const newDialogues: Dialogue[] = [];
    for (const d of dialogues) {
      const newVws: UserVideoWord[] = [];
      for (const uvw of d.userVideoWords) {
        let newWord: UserVideoWord;
        if (uvw.wordKey == word.wordKey) {
          newWord = { ...uvw, status: newStatus };
        } else {
          newWord = uvw;
        }
        newVws.push(newWord);
      }
      const newDialog = {
        ...d,
        userVideoWords: newVws,
      };
      newDialogues.push(newDialog);
    }
    return newDialogues;
  };

  const handleTimeUpdate = (timeMs: number) => {
    if (!pauseHandleTimeUpdate.current) {
      setCurrentTime(timeMs);
    }
  };

  const handlePlay = () => {
    pauseHandleTimeUpdate.current = false;
  };

  return (
    <div className="flex flex-col items-center w-full h-full justify-between max-w-[530px]">
      <VideoPlayer
        ref={videoRef}
        url={url as string}
        onTimeUpdate={handleTimeUpdate}
        startTime={startTime as number}
        endTime={endTime as number}
        onPlay={handlePlay}
        onTrackListeningTime={onTrackListeningTime}
      />
      <div className="flex flex-col justify-between flex-grow gap-4 sm:gap-2 p-4 sm:p-2 w-full">
        <VideoContent
          currentTime={currentTime as number}
          dialogues={dialogues}
          onWordClick={handleWordClick}
          onKnownClick={handleKnownClick}
          displayHelp={displayHelp}
          targetWord={targetWord}
          nativeWord={nativeWord}
          selectedWordStatus={selectedWord.status}
        />
        <div className="flex flex-col justify-between gap-4 sm:gap-2">
          <LessonBlock
            lessonNumber={lesson.sequence + 1}
            description={lesson.description}
            status={"current"}
            onClick={() => navigate("/chapter/" + chapterId)}
          />
          <Button
            onClick={() => {
              onActivityFinished();
            }}
            size={"lg"}
            className="w-full"
          >
            Let's practice
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
