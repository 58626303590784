import React from "react";

interface TitleProps {
  text: string;
}

const Title: React.FC<TitleProps> = ({ text }) => {
  return (
    <div className="text-black text-start text-2xl font-semibold mb-2 mt-2">
      {text}
    </div>
  );
};

export default Title;
