import React from "react";
import {
  Card,
  CardBody,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { PiDropFill } from "react-icons/pi";
import { PiTreeFill } from "react-icons/pi";

interface DropsHighlightProps {
  dropsNeeded: number;
  dropsCount: number;
  onClick?: () => void;
}

const DropsHighlight: React.FC<DropsHighlightProps> = ({
  dropsNeeded,
  dropsCount,
  onClick,
}) => {
  let variant: string;
  let icon: JSX.Element;
  let subtitle: JSX.Element;
  let progressStyle: React.CSSProperties = {};

  switch (dropsNeeded) {
    case 0:
      variant = "green";
      icon = <PiTreeFill className="w-8 h-8 text-white" />;
      subtitle = (
        <>
          Press the icon{" "}
          <span className="font-semibold">to plant your next tree! ⤴</span>
        </>
      );
      progressStyle = {
        borderRadius: "50%",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.22)",
        cursor: "pointer",
      };
      break;
    default:
      variant = "blue";
      icon = <PiDropFill className="w-8 h-8 text-white" />;
      subtitle = (
        <span>
          Earn <span className="font-semibold">{dropsNeeded} more drops</span>{" "}
          to plant <span className="font-semibold">your next tree</span>
        </span>
      );
  }

  const progressVal = (dropsCount / (dropsCount + dropsNeeded)) * 100;
  const totalDrops = dropsCount + dropsNeeded;

  return (
    <Card className="w-full h-auto" variant={variant}>
      <CardBody className="flex flex-col items-start text-start gap-4 p-3">
        <div className="flex flex-row w-full justify-between">
          <div className="text-xl font-normal w-3/4">
            You have{" "}
            <span className="font-bold">
              {dropsCount}/{totalDrops}
            </span>{" "}
            Water Drops
          </div>
          <div className="self-end">
            <CircularProgress
              value={progressVal}
              size="75px"
              thickness="6px"
              color="white"
              trackColor="var(--color-blue)"
              style={progressStyle}
              onClick={onClick}
            >
              <CircularProgressLabel>
                <div className="flex items-center justify-center">{icon}</div>
              </CircularProgressLabel>
            </CircularProgress>
          </div>
        </div>
        <div className="text-sm mt-3">{subtitle}</div>
      </CardBody>
    </Card>
  );
};

export default DropsHighlight;