import React from "react";
import InsightsCard from "./InsightsCard";
import { Indexes } from "./CardCarousel";

interface CardListProps {
  cardItems: CardItem[];
  indexes: Indexes;
}

interface CardItem {
  id: number;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const determineClasses = (indexes: Indexes, cardIndex: number): string => {
  if (indexes.currentIndex === cardIndex) {
    return "active";
  } else if (indexes.nextIndex === cardIndex) {
    return "next";
  } else if (indexes.previousIndex === cardIndex) {
    return "prev";
  }
  return "inactive";
};

const CardList: React.FC<CardListProps> = ({ cardItems, indexes }) => {
  return (
    <ul className="card-carousel">
      {cardItems.map((card, index) => (
        <InsightsCard
          key={card.id}
          card={card}
          className={determineClasses(indexes, index)}
        />
      ))}
    </ul>
  );
};

export default CardList;