import React from "react";
import ChapterHeader from "@/components/ChapterPath/ChapterHeader";
import LessonBlock from "@/components/ChapterPath/LessonBlock";
import { useNavigate, useParams } from "react-router-dom";
import earth from "../assets/earth.png";
import {
  getChapterInfo,
  callWithAuth,
  GetChapterInfoRequest,
} from "../api/Api";
import { useQuery } from "@tanstack/react-query";

const ChapterPathPage: React.FC = () => {
  const chapterId = useParams().chapterId;
  const chapterIdNumber = Number(chapterId);

  const chapterInfoQuery = useQuery({
    queryKey: ["chapterInfo"],
    queryFn: () => {
      const data: GetChapterInfoRequest = {
        chapterId: chapterIdNumber,
      };
      return callWithAuth(getChapterInfo, data);
    },
  });
  const chapter = chapterInfoQuery.data?.chapter;
  const lessons = chapterInfoQuery.data?.lessons;

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/home/learn");
  };

  const handleLessonClick = (lessonId: number, status: string) => {
    if (status !== "locked") {
      navigate(`/chapter/${chapterId}/lesson/${lessonId}`);
    }
  };

  return (
    <div className="appPadding flex flex-col h-screen w-full overflow-hidden items-center z-0">
      <div className="w-full self-start">
        <ChapterHeader
          title={chapter ? chapter.title : ""}
          handleBack={handleBack}
        />
      </div>
      <div className="flex flex-col items-center gap-4 overflow-y-scroll w-full pb-4">
        {Array.isArray(lessons) &&
          lessons.map((lesson, index) => (
            <LessonBlock
              key={index}
              lessonNumber={lesson.sequence + 1}
              description={lesson.description}
              status={lesson.status}
              onClick={() => handleLessonClick(lesson.lessonId, lesson.status)}
            />
          ))}
      </div>
      <div className="w-full h-1/5 self-end pt-9">
        <img src={earth} alt="Earth" className="w-full" />
      </div>
    </div>
  );
};

export default ChapterPathPage;
