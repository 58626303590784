import React from "react";
import { useParams } from "react-router-dom";
import ParrotText from "../components/ParrotText";
import ContinueButton from "../components/Buttons/ContinueButton";
import { useNavigate } from "react-router-dom";
import InfoBox from "../components/InfoBox";

const NamePage: React.FC = () => {
  const chapterId = useParams().chapterId;
  const nav = useNavigate();

  const handleContinue = () => {
    nav(`/chapter/${chapterId}`);
  };

  return (
    <div className="appPadding flex flex-col h-full w-full overflow-hidden items-center justify-between">
      <div className="absolute top-16">
        <ParrotText />
      </div>
      <div className="w-full flex flex-grow justify-center">
        <InfoBox
          title="General Spanish 🍃"
          subtitle="This general Spanish course is designed to include the most common 1,000 words in Spanish and get you started on a great track!"
        />
      </div>
      <div className="w-full pb-16">
        <ContinueButton onClick={handleContinue} />
      </div>
    </div>
  );
};

export default NamePage;
