import React from 'react';
import { BiBook } from 'react-icons/bi';

interface DictionaryButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const DictionaryButton: React.FC<DictionaryButtonProps> = ({ isActive, onClick }) => {

  return (
    <button
      onClick={onClick}
      className={`h-20 w-20 rounded-lg ${ isActive ? 'text-black' : 'text-gray-500'}`}
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <BiBook className="h-8 w-8" />
        <p className="text-sm font-medium">{'dictionary'}</p>
      </div>
    </button>
  );
};

export default DictionaryButton;
