import BackButton from "@/components/Buttons/BackButton";
import React from "react";
import InfoBox from "@/components/InfoBox";
import DropsHighlight from "@/components/DropsHighlight";
import { getUserInfo, callWithAuth } from "@/api/Api";
import { useQuery } from "@tanstack/react-query";
import PlantAnimation from "../components/PlantAnimation";
import { Modal, ModalOverlay, ModalContent, useDisclosure } from "@chakra-ui/react";

const PlantTreePage: React.FC = () => {
  const { isOpen, onOpen } = useDisclosure();
  const userInfoQuery = useQuery({
    queryKey: ["userInfo"],
    queryFn: () => {
      return callWithAuth(getUserInfo);
    },
  });

  const firstName = userInfoQuery.data?.firstName || "";

  const handleTreePlant = () => {
    onOpen();
  };

  return (
    <div className="appPadding flex flex-col items-center justify-between w-full h-full overflow-hidden">
      <div className="self-start pt-4 pb-8">
        <BackButton handleBack={() => window.history.back()} />
      </div>
      <div className="flex w-full items-center justify-center">
        <InfoBox
          title={`Congrats, ${firstName}!`}
          subtitle={
            <>
              <p>👏🏻👏🏻👏🏻</p>
              <p>
                You've earned enough water droplets to{" "}
                <span className="font-bold">plant a tree</span>!
              </p>
            </>
          }
        />
      </div>
      <div className="flex flex-col flex-grow items-center justify-center w-full h-full">
        <p>🌍 We plant real trees on earth, for you</p>
        <p className="mt-4">💨 Your trees absorb CO2 emissions</p>
        <p className="mt-4">📲 Save your hard earned progress</p>
      </div>
      <div className="w-full pb-16">
        <DropsHighlight dropsCount={1000} dropsNeeded={0} onClick={handleTreePlant}/>
      </div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay backdropFilter='blur(10px)'/>
        <ModalContent>
            <PlantAnimation />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PlantTreePage;