import React from "react";

// Define padding variable

const TermsOfService: React.FC = () => {
const headingPadding = "py-6 px-2";
  return (
    <div className="max-w-5xl mx-auto p-5 text-left">
      <h1 className={`text-4xl font-bold ${headingPadding}`}>Terms of Service</h1>

      <h2 className={`text-3xl font-bold ${headingPadding}`}>Terms of Service (ToS) for Parrot</h2>
      <p>
        <strong>Effective Date</strong>: September 2024
      </p>

      <p>
        Welcome to Parrot! These Terms of Service ("Terms") govern your use of
        the Parrot mobile application ("App"), website, and all related services
        (collectively, "Services"). By using our Services, you agree to be bound
        by these Terms. If you do not agree to these Terms, you should not use
        our Services.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>1. Acceptance of Terms</h3>
      <p>
        By creating an account or otherwise accessing or using Parrot, you
        confirm that you are at least 13 years of age, or, if under 13, your
        usage is under parental guidance. You agree to these Terms and
        acknowledge our <strong>Privacy Policy</strong>.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>2. Changes to the Terms</h3>
      <p>
        We may modify these Terms at any time. Any changes will be posted within
        the app or website, and by continuing to use the Services, you agree to
        the modified Terms.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>3. Account Registration and Responsibility</h3>
      <ul className="list-disc pl-5 text-left">
        <li>Provide accurate and complete information during registration.</li>
        <li>Keep your login credentials confidential.</li>
        <li>Notify us of any unauthorized use of your account.</li>
        <li>
          Be responsible for all activities that occur under your account.
        </li>
      </ul>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>4. Use of the Services</h3>
      <p>
        You agree to use the Services only for lawful purposes and in compliance
        with all applicable Canadian laws. You may not:
      </p>
      <ul className="list-disc pl-5 text-left">
        <li>
          Use the Services to engage in harmful, illegal, or disruptive
          activities.
        </li>
        <li>
          Reverse engineer, decompile, or extract source code from the App.
        </li>
        <li>
          Exploit or attempt to interfere with the security of the Services.
        </li>
      </ul>
      <p>
        Parrot reserves the right to suspend or terminate your access to the
        Services if we determine that your actions violate these Terms or harm
        our community.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>5. Subscription and Fees</h3>
      <p>
        Some features of Parrot may require a subscription. The pricing and
        payment terms will be clearly outlined during the purchase process. You
        agree to pay all applicable fees and taxes associated with your use of
        Parrot's paid services. Subscription fees are charged at the beginning
        of the subscription period, and auto-renew unless canceled.
      </p>
      <p>
        Refunds will only be provided as required by Canadian law or as stated
        in our <strong>Money-Back Guarantee</strong> policy.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>6. Intellectual Property</h3>
      <p>
        All features, functionality, and content within the Parrot app,
        including but not limited to text, graphics, logos, user interfaces,
        software code, and design elements, are the exclusive intellectual
        property of Parrot or its licensors. You may not reproduce, distribute,
        or otherwise use these materials without our express written consent.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>7. Third-Party Services and Links</h3>
      <p>
        Our Services may contain links to third-party websites, products, or
        services. We are not responsible for the content or policies of those
        third parties and recommend you review their terms and privacy policies
        before interacting with them.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>8. User-Generated Content</h3>
      <p>
        You may be able to upload, post, or share content, such as progress
        reports, images, or other communications, through our Services ("User
        Content"). By doing so, you:
      </p>
      <ul className="list-disc pl-5 text-left">
        <li>
          Grant us a worldwide, non-exclusive, royalty-free license to use,
          modify, and display your User Content in connection with the Services.
        </li>
        <li>
          Represent that your User Content does not infringe any third-party
          rights.
        </li>
      </ul>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>9. Limitation of Liability</h3>
      <p>
        To the maximum extent permitted by law, Parrot shall not be liable for
        any direct, indirect, incidental, or consequential damages arising from
        your use of the Services. Our total liability to you shall not exceed
        the total fees paid by you for the Services over the last six months.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>10. Termination</h3>
      <p>
        We reserve the right to terminate or suspend your account at any time,
        without prior notice or liability, for any reason. Upon termination,
        your right to use the Services will immediately cease.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>11. Governing Law</h3>
      <p>
        These Terms are governed by and construed in accordance with the laws of
        Canada. Any disputes shall be resolved exclusively by the courts of
        Canada.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>12. Contact Us</h3>
      <p>
        If you have any questions or concerns regarding these Terms, please
        contact us at{" "}
        <a href="mailto:support@fluentwithparrot.com">
          support@fluentwithparrot.com
        </a>
        .
      </p>
    </div>
  );
};

export default TermsOfService;