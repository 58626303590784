// Functions to get all the queries we need to satisfy the api
// api.ts
import axios, { AxiosError } from "axios";
import { tokenStorage } from "./tokenStorage";
import {
  Course,
  Chapter,
  Lesson,
  UserWord,
  VideoActivity,
  FillInTheBlankActivity,
  MatchingActivity,
  WriteActivity,
  GuessActivity,
  StatsData,
} from "../components/Types";

// Base URL configuration
let BASE_URL;
if (window && window.location.href.includes("192")) {
  BASE_URL = "http://192.168.1.97:8090";
} else if (window && window.location.href.includes("localhost")) {
  BASE_URL = "http://127.0.0.1:8090";
} else {
  BASE_URL = "/papi/";
}
const BAD_AUTH_REDIRECT_URL = "/";

export const API_BASE_URL = BASE_URL;

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Error handling
function handleAxiosError(error: AxiosError): never {
  const status = error.response?.status;
  const unAuthorizedStatus = 401;

  // anytime an unathorized request goes through in our app,
  // this will redirect the user to where we want em
  if (status == unAuthorizedStatus) {
    // redirect user to onBoarding
    window.location.assign(BAD_AUTH_REDIRECT_URL);
    // clean the token from storage since its bad and wont be useful anymore
    tokenStorage.clearToken();
  }

  let message = error.message; // Default to the error message from Axios

  if (error.response?.data && typeof error.response.data === "object") {
    const data = error.response.data as { message?: string };
    if (data.message) {
      message = data.message;
    }
  }

  console.error(`Error: ${message} (Status Code: ${status})`);
  throw new Error(message);
}

// this function checks to see if we are logged in.
// this means the token in local storage is valid
// we want to minimize reliance on pocketbase which has a
// changing api
export async function isLoggedIn(): Promise<boolean> {
  if (!tokenStorage.isTokenSet()) {
    return false;
  }
  const token = tokenStorage.getToken();
  const valid = await isTokenValid(token);
  return valid;
}

async function isTokenValid(token: string): Promise<boolean> {
  // try a small endpoint with auth
  // if we get a 401 return false
  // improvement could be to make an enpoint just for this,
  // incase this one changes
  try {
    await axiosInstance.get<GetWaterDropletsResponse>("/auth/waterdroplets", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (error) {
    const badAuth = (error as AxiosError).response?.status == 401;
    if (badAuth) {
      return false;
    }
    return true;
  }
}

// Define a generic type for the functions that will be passed into callWithAuth
type AuthFunction<T, R> = (args: { authToken: string; data?: T }) => Promise<R>;

// callWithAuth function definition
// It tries to read the token from local storage
// if its missing we will log an error and redirect.
// The request will be a type defined in this file for the request
// the browser to login page
// GetRequest need to use params
export function callWithAuth<T, R>(
  f: AuthFunction<T, R>,
  data?: T,
): Promise<R> {
  if (!tokenStorage.isTokenSet()) {
    window.location.assign(BAD_AUTH_REDIRECT_URL);
  }
  const authToken = tokenStorage.getToken();
  const params: AuthFuncParams<T> = {
    authToken,
    data,
  };
  return f(params);
}

// All get has params and not request
export interface AuthFuncParams<T> {
  authToken: string;
  data?: T;
}

// Types for requests and responses

export interface GenericMessageResponse {
  message: string;
}

export interface ErrorResponse {
  error: string;
}

export interface PostTempUserRequest {
  firstName: string;
  email: string;
}

export interface PostTempUserResponse {
  message: string;
  parrotUserId: number;
  token: string;
}

export interface GetLessonDataRequest {
  lessonId: number;
}

export interface GetLessonDataResponse {
  lesson: Lesson;
  video: VideoActivity;
  fill: FillInTheBlankActivity;
  guess: GuessActivity;
  write: WriteActivity;
  match: MatchingActivity;
  isLastChapter: boolean; // marks if this is an end to a course
  isLastLesson: boolean; // marks if this in an end to a lesson
  nextLessonId: number;
}

export interface PostChapterCompletedRequest {
  chapterId: number;
}

export interface PostChapterCompletedResponse {
  message: string;
  waterDropletsAwarded: number;
}

export interface PostLessonCompletedRequest {
  lessonId: number;
}

export interface PostLessonCompletedResponse {
  message: string;
  waterDropletsAwarded: number;
}

export interface PutWordStatusRequest {
  wordKey: string;
  newStatus: string;
  definitionId: number;
}

export interface PutWordStatusResponse {
  message: string;
  waterDropletsAwarded: number;
}

export interface PostActivityCompleteRequest {
  lessonId: number;
  activityType: string;
}

export interface PostActivityCompleteResponse {
  message: string;
  waterDropletsAwarded: number;
}

export interface GetChapterInfoRequest {
  chapterId: number;
}

export interface GetChapterInfoResponse {
  chapter: Chapter;
  lessons: Lesson[];
}

export interface GetCourseInfoRequest {
  courseId: number;
}

export interface GetCourseInfoResponse {
  course: Course;
  chapters: Chapter[];
}

export interface GetPlantStatsResponse {
  chaptersCompleted: number;
  waterDropletsCount: number;
  waterDropletsNeeded: number;
  treesPlanted: number;
  co2Captured: number;
}

export interface GetDictionaryStatsResponse {
  knownWordCount: number;
  learningWordCount: number;
}

export interface GetKnownWordsDictionaryResponse {
  knownWords: UserWord[];
}

export interface GetWordInfoRequest {
  wordKey: string;
}

export interface GetWordInfoResponse {
  definition: string;
  sentence: string;
  otherUsage: string;
}

export interface GetLearningWordsDictionaryResponse {
  learningWords: UserWord[];
}

export interface GetUserInfoResponse {
  id: number;
  firstName: string;
  email: string;
  joinDate: string;
  isTemp: boolean;
  avatarUrl: string;
}

export interface GetUserStatsResponse {
  listeningTime: number;
  wordsKnown: number;
  dayStreak: number;
  waterDropletsCount: number;
  waterDropletsNeeded: number;
  treesPlanted: number;
  chaptersCompleted: number;
  co2Captured: number;
  timeToNextLevel: number;
  level: number;
}

export interface GetStatsTableRequest {
  tableType: string;
}

export interface GetStatsTableResponse {
  tableName: string;
  data: StatsData[];
}

export interface GetIsFirstLessonClickResponse {
  isFirstClick: boolean;
}

export interface PutTempUserToFullUserRequest {
  parrotUserId: number;
}

export interface GetWaterDropletsResponse {
  waterDropletsCount: number;
}

export interface PostListenTimeRequest {
  listenTime: number;
}

export interface PostListenTimeResponse extends GenericMessageResponse {}

// API Request Functions
export async function postListenTime({
  data,
  authToken,
}: AuthFuncParams<PostListenTimeRequest>): Promise<PostListenTimeResponse> {
  const request = data;
  try {
    const response = await axiosInstance.post<PostListenTimeResponse>(
      "/auth/listentime",
      request,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function postNewUser({
  authToken,
}: AuthFuncParams<void>): Promise<GenericMessageResponse> {
  try {
    const response = await axiosInstance.post<GenericMessageResponse>(
      "/auth/newuser",
      undefined,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function postTempUser(
  data: PostTempUserRequest,
): Promise<PostTempUserResponse> {
  const request = data;
  try {
    const response = await axiosInstance.post<PostTempUserResponse>(
      "/guest/tempuser",
      request,
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getLessonData({
  data,
  authToken,
}: AuthFuncParams<GetLessonDataRequest>): Promise<GetLessonDataResponse> {
  const params = data;
  try {
    const response = await axiosInstance.get<GetLessonDataResponse>(
      "/auth/lessondata",
      {
        params,
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function postChapterCompleted({
  data,
  authToken,
}: AuthFuncParams<PostChapterCompletedRequest>): Promise<PostChapterCompletedResponse> {
  const request = data;
  try {
    const response = await axiosInstance.post<PostChapterCompletedResponse>(
      "/auth/chaptercompleted",
      request,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function postLessonCompleted({
  data,
  authToken,
}: AuthFuncParams<PostLessonCompletedRequest>): Promise<PostLessonCompletedResponse> {
  const request = data;
  try {
    const response = await axiosInstance.post<PostLessonCompletedResponse>(
      "/auth/lessoncompleted",
      request,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function putWordStatus({
  data,
  authToken,
}: AuthFuncParams<PutWordStatusRequest>): Promise<PutWordStatusResponse> {
  const request = data;
  try {
    const response = await axiosInstance.put<PutWordStatusResponse>(
      "/auth/wordstatus",
      request,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function postActivityComplete({
  data,
  authToken,
}: AuthFuncParams<PostActivityCompleteRequest>): Promise<PostActivityCompleteResponse> {
  const request = data;
  try {
    const response = await axiosInstance.post<PostActivityCompleteResponse>(
      "/auth/activitycomplete",
      request,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getChapterInfo({
  data,
  authToken,
}: AuthFuncParams<GetChapterInfoRequest>): Promise<GetChapterInfoResponse> {
  const params = data;
  try {
    const response = await axiosInstance.get<GetChapterInfoResponse>(
      "/auth/chapterinfo",
      {
        params,
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getCourseInfo({
  data,
  authToken,
}: AuthFuncParams<GetCourseInfoRequest>): Promise<GetCourseInfoResponse> {
  const params = data;
  try {
    const response = await axiosInstance.get<GetCourseInfoResponse>(
      "/auth/courseinfo",
      {
        params,
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getPlantStats({
  authToken,
}: AuthFuncParams<void>): Promise<GetPlantStatsResponse> {
  try {
    const response = await axiosInstance.get<GetPlantStatsResponse>(
      "/auth/plantstats",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getDictionaryStats({
  authToken,
}: AuthFuncParams<void>): Promise<GetDictionaryStatsResponse> {
  try {
    const response = await axiosInstance.get<GetDictionaryStatsResponse>(
      "/auth/dictionarystats",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getKnownWordsDictionary({
  authToken,
}: AuthFuncParams<void>): Promise<GetKnownWordsDictionaryResponse> {
  try {
    const response = await axiosInstance.get<GetKnownWordsDictionaryResponse>(
      "/auth/knownwords",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getLearningWordsDictionary({
  authToken,
}: AuthFuncParams<void>): Promise<GetLearningWordsDictionaryResponse> {
  try {
    const response =
      await axiosInstance.get<GetLearningWordsDictionaryResponse>(
        "/auth/learningwords",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        },
      );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getWordInfo({
  data,
  authToken,
}: AuthFuncParams<GetWordInfoRequest>): Promise<GetWordInfoResponse> {
  const params = data;
  try {
    const response = await axiosInstance.get<GetWordInfoResponse>("/wordinfo", {
      params,
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getUserInfo({
  authToken,
}: AuthFuncParams<void>): Promise<GetUserInfoResponse> {
  try {
    const response = await axiosInstance.get<GetUserInfoResponse>(
      "/auth/userinfo",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getUserStats({
  authToken,
}: AuthFuncParams<void>): Promise<GetUserStatsResponse> {
  try {
    const response = await axiosInstance.get<GetUserStatsResponse>(
      "/auth/userstats",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getStatsTable({
  data,
  authToken,
}: AuthFuncParams<GetStatsTableRequest>): Promise<GetStatsTableResponse> {
  const params = data;
  try {
    const response = await axiosInstance.get<GetStatsTableResponse>(
      "/auth/statstable",
      {
        params,
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getIsFirstLessonClick({
  authToken,
}: AuthFuncParams<void>): Promise<GetIsFirstLessonClickResponse> {
  try {
    const response = await axiosInstance.get<GetIsFirstLessonClickResponse>(
      "/auth/isfirstlessonclick",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function putTempUserToFullUser({
  data,
  authToken,
}: AuthFuncParams<PutTempUserToFullUserRequest>): Promise<GenericMessageResponse> {
  const request = data;
  try {
    const response = await axiosInstance.put<GenericMessageResponse>(
      "/auth/tempusertofulluser",
      request,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}

export async function getWaterDroplets({
  authToken,
}: AuthFuncParams<any>): Promise<GetWaterDropletsResponse> {
  try {
    const response = await axiosInstance.get<GetWaterDropletsResponse>(
      "/auth/waterdroplets",
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error as AxiosError);
  }
}
