import React, { useMemo } from "react";
import Title from "../Title";
import SoundButton from "../Buttons/SoundButton";
import ActivityButton from "../Buttons/ActivityButton";
import { GuessActivity, Option } from "../Types";

const shuffleOptions = (options: Option[]): Option[] => {
  return options.sort(() => Math.random() - 0.5);
};

interface MCQProps {
  guessActivity: GuessActivity;
  onActivityFinished: () => void;
  lessonId: number;
}

const MCQ: React.FC<MCQProps> = ({
  guessActivity,
  lessonId,
  onActivityFinished,
}) => {
  // prevents shuffling when we re-render component
  const shuffledOptions = useMemo(
    () => shuffleOptions(guessActivity.options),
    [lessonId],
  );

  const handleOptionClick = async (option: Option) => {
    if (option.correct) {
      onActivityFinished();
    }
  };

  return (
    <div className="appPadding flex flex-col items-center w-full gap-6">
      <Title text="What does this mean?" />
      <SoundButton text={guessActivity.question} />
      <div className="flex flex-col gap-4 w-52 pt-10">
        {shuffledOptions.map((option) => (
          <ActivityButton
            key={option.choice}
            text={option.choice}
            isCorrect={option.correct}
            onClick={() => handleOptionClick(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default MCQ;
