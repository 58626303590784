import React from "react";
import BackButton from "../Buttons/BackButton";
import Droplets from "../HomeHeader/Droplets";
import { IoHome } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, AbsoluteCenter } from "@chakra-ui/react";

interface LessonHeaderProps {
  handleBack: () => void;
  pulse: boolean;
}

const LessonHeader: React.FC<LessonHeaderProps> = ({ handleBack, pulse}) => {
  const nav = useNavigate();
  return (
    <Box width={"100%"} position={"relative"} className="appPadding pb-0">
      <div className="flex flex-row items-center justify-between w-full z-10 relative">
        <BackButton handleBack={handleBack} />
        <Droplets pulse={pulse}/>
        <AbsoluteCenter verticalAlign={"middle"} zIndex={10}>
        <IconButton
          icon={<IoHome className="w-6 h-6" />}
          variant="ghost"
          aria-label="home"
          onClick={() => {
            nav("/home/learn");
          }}
        />
      </AbsoluteCenter>
      </div>
    </Box>
  );
};

export default LessonHeader;
