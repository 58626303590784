import React from "react";
import { Avatar } from "@chakra-ui/react";

interface ResponseMessageProps {
  message: string;
  className?: string;
}

const ResponseMessage: React.FC<ResponseMessageProps> = ({
  className,
  message,
}) => {
  return (
    <div className="flex justify-end w-full h-auto mb-2">
      <div
        className={`text-[var(--color-blue)] box-content h-6 bg-[#F2F8FF] font-medium rounded-xl rounded-tr-none p-3 w-auto text-start ${className}`}
      >
        {message}
      </div>
      <Avatar size="sm" ml={2} />
    </div>
  );
};

export default ResponseMessage;
