import React from "react";
import { PiDropFill } from "react-icons/pi";
import ChapterNode from "./ChapterNode";

const ChapterNodeInProgress: React.FC<{ onClick: () => void }> = ({ onClick }) => {

  return (
    <div className="flex flex-col items-center justify-center gap-2">
    <div className="flex w-20 p-1 border-2 text-sm font-bold uppercase items-center justify-center text-center text-[var(--color-green)] bg-white rounded-xl animate-bounce tracking-wide z-10">
      Learn
      <div className="absolute left-1/2 -bottom-2 w-0 h-0 border-x-8 border-x-transparent border-t-8 transform -translate-x-1/2" />
    </div>
    <ChapterNode
      status="current"
      icon={<PiDropFill className="text-white w-8 h-8" />}
      bgColor="bg-[var(--color-yellow)]"
      borderColor="border-[#c99500]"
      onClick={onClick}
    />
    </div>
  );
};

export default ChapterNodeInProgress;