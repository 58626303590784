import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeLayout from "./pages/HomeLayout";
import StartPage from "./pages/StartPage";
import OnboardingPage from "./pages/OnboardingPage";
import CourseInfoPage from "./pages/CourseInfoPage";
import ChapterPathPage from "./pages/ChapterPathPage";
import ConstructionPage from "./pages/ConstructionPage";
import PlantTreePage from "./pages/PlantTreePage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import Lesson from "./pages/Lesson";
import LearnPage from "./pages/LearnPage";
import DictionaryPage from "./pages/DictionaryPage";
import ProfilePage from "./pages/ProfilePage";
import TermsOfService from "./pages/Terms.tsx";
import Privacy from "./pages/Privacy.tsx";
import { Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { isLoggedIn } from "./api/Api.tsx"; // assuming isLoggedIn is your auth check function

const queryClient = new QueryClient();

const AppHomePath = "/home";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route
              path="/onboarding/:name?/:email?"
              element={<OnboardingPage />}
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route
              path={AppHomePath}
              element={
                <ProtectedRoute>
                  <HomeLayout />
                </ProtectedRoute>
              }
            >
              <Route path="learn" element={<LearnPage />} />
              <Route path="dictionary" element={<DictionaryPage />} />
              <Route path="profile" element={<ProfilePage />} />
            </Route>
            <Route
              path="/info/:chapterId"
              element={
                <ProtectedRoute>
                  <CourseInfoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chapter/:chapterId"
              element={
                <ProtectedRoute>
                  <ChapterPathPage />
                </ProtectedRoute>
              }
            />
            <Route path="/construction" element={<ConstructionPage />} />
            <Route
              path="/planttree"
              element={
                <ProtectedRoute>
                  <PlantTreePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chapter/:chapterId/lesson/:lessonId/*"
              element={
                <ProtectedRoute>
                  <Lesson />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  // this query key must be different than
  // the redirect away elsewhere or we will get cache issues
  const result = useQuery({
    queryKey: ["AuthQueryProtectedRoute"],
    queryFn: () => isLoggedIn(),
  });

  if (result.isLoading) {
    return <div></div>;
  }
  // login bool checked
  if (result.data === false) {
    return <Navigate to="/" />;
  }

  return children;
};

export default App;
