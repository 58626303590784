import React from "react";
//import { PiSpeakerHigh } from "react-icons/pi";
import { Button } from "@chakra-ui/react";

interface SoundButtonProps {
  text: string;
}

const SoundButton: React.FC<SoundButtonProps> = ({ text }) => {
  // since we have no sound yet I added styles to temp disable the button
  return (
    <Button
      variant="outline"
      disabled={true}
      bg={"white"}
      className="flex items-center text-lg cursor-default hover:!bg-white focus:!bg-white active:!bg-white"
    >
      <span>{text}</span>
    </Button>
  );
};

export default SoundButton;
