import React, { useState, useMemo } from "react";
import { Button } from "../Buttons/Button";
import { SimpleGrid } from "@chakra-ui/react";
import ParrotMessage from "../Chat/ParrotMessage";
import ResponseMessage from "../Chat/ResponseMessage";
import { WriteActivity } from "../Types";

const shuffleOptions = (options: string[]): string[] => {
  return options.sort(() => Math.random() - 0.5);
};

interface WriteProps {
  writeActivity: WriteActivity;
  onActivityFinished: () => void;
  lessonId: number;
}

type State = "start" | "selected";

interface WriteButtonState {
  id: number;
  state: State;
  char: string;
}
interface SelectedOption {
  id: number;
  char: string;
}

const Write: React.FC<WriteProps> = ({
  writeActivity,
  onActivityFinished,
  lessonId,
}) => {
  const answerString = writeActivity.answer.toLowerCase();
  const options = useMemo(
    () => shuffleOptions(answerString.split("")),
    [lessonId],
  );
  const [timesIncorrect, setTimesIncorrect] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [responseState, setResponseState] = useState<
    "start" | "incorrect" | "correct"
  >("start");
  // we build the button states from looking at which chatarcters area already
  // selected
  const selectButtons: WriteButtonState[] = options.map(
    (char: string, index: number) => {
      // change selected char state
      for (const option of selectedOptions) {
        if (char == option.char && option.id == index) {
          return {
            id: index,
            state: "selected",
            char: char,
          };
        }
      }
      return {
        id: index,
        state: "start",
        char: char,
      };
    },
  );

  const handleClear = () => {
    setResponseState("start");
    setSelectedOptions([]);
  };

  const handleOptionClick = (button: WriteButtonState) => {
    // remove the button clicked from the selected options starting at the back
    if (button.state == "selected") {
      setSelectedOptions((prev) => {
        const toRemoveId = button.id;
        const newSelectedOptions = prev.filter(
          (option) => toRemoveId != option.id,
        );
        setResponseState("start");
        // before set state check win
        return newSelectedOptions;
      });
    } else {
      setSelectedOptions((prev) => {
        const newSelectedOption = {
          id: button.id,
          char: button.char,
        };
        const newSelectedOptions = [...prev, newSelectedOption];
        // before set state check win
        const wordInputFinished = newSelectedOptions.length == options.length;
        if (!wordInputFinished) {
          return newSelectedOptions;
        }
        const gotAnswer = newSelectedOptions.reduce((acc, selectedOptions) => {
          return acc + selectedOptions.char;
        }, "");
        if (gotAnswer != answerString.toLowerCase()) {
          // display incorrect feedback
          setTimesIncorrect((prev) => prev + 1);
          setResponseState("incorrect");
          return newSelectedOptions;
        }
        setResponseState("correct");
        onActivityFinished();
        return newSelectedOptions;
      });
    }
  };

  const selectedOptionsText = selectedOptions.reduce((acc, selectedOptions) => {
    return acc + " " + selectedOptions.char;
  }, "");

  const grayedOutCls = "!bg-[#b5b5b5] text-white !opacity-60";

  const displayClearCls = selectedOptions.length > 0 ? "visible" : "invisible";

  const wrongAwnserCls = "bg-red-600 bg-opacity-80 text-white";
  const correctAnswerCls = "bg-green-600 bg-opacity-80 text-white";
  let responseCls;
  switch (responseState) {
    case "correct":
      responseCls = correctAnswerCls;
      break;
    case "incorrect":
      responseCls = wrongAwnserCls;
      break;
    default:
      responseCls = "";
      break;
  }
  const showHelpTextCls = timesIncorrect >= 3 && "!visible";

  return (
    <div className="appPadding flex flex-col items-center w-full gap-6 px-6">
      <div className="w-full text-xl font-bold">
        Translate this word and use the buttons to write it out
      </div>
      <ParrotMessage message={writeActivity.question} />
      <ResponseMessage className={responseCls} message={selectedOptionsText} />
      <div
        className={`text-[var(--color-orange)] invisible ${showHelpTextCls}`}
      >
        pstt... it's {answerString}
      </div>
      <SimpleGrid columns={4} spacing={3} className="p-4">
        {selectButtons.map((buttonState) => (
          <Button
            key={buttonState.id}
            onClick={() => handleOptionClick(buttonState)}
            size={"default"}
            className={buttonState.state == "selected" ? grayedOutCls : ""}
          >
            {buttonState.char}
          </Button>
        ))}
      </SimpleGrid>
      <div className={"flex gap-4 " + displayClearCls}>
        <Button onClick={handleClear} size={"default"}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default Write;
