import React from 'react';
import DictionaryButton from './DictionaryButton';
import ProfileButton from './ProfileButton';
import LearnButton from './LearnButton';
import { NavigateFunction } from 'react-router-dom';

type ActivePage = 'learn' | 'dictionary' | 'profile';

interface NavBarProps {
  activePage: ActivePage;
  navigate: NavigateFunction;
}

const NavBar: React.FC<NavBarProps> = ({ activePage, navigate }) => {
  return (
    <div className="flex justify-evenly items-center align-middle fixed bottom-0 left-0 right-0 w-full border-t border-gray-300 bg-white">
      <LearnButton isActive={activePage === 'learn'} onClick={() => navigate('/home/learn')} />
      <DictionaryButton isActive={activePage === 'dictionary'} onClick={() => navigate('/home/dictionary')} />
      <ProfileButton isActive={activePage === 'profile'} onClick={() => navigate('/home/profile')} />
    </div>
  );
};

export default NavBar;