import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import tree from "../../assets/tree1.png";
import { Button } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

interface ChatProps {
  name: string;
  onEmailReset: () => void;
  disableBack?: boolean;
}

const Chat: React.FC<ChatProps> = ({ name, onEmailReset, disableBack }) => {
  const nav = useNavigate();
  const [level, setLevel] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);

  const messagesTemplate = [
    {
      text: [
        <span key="1">
          Hi {name}, thanks for choosing Parrot for your Spanish learning
          journey!
        </span>,
      ],
      options: ["Click me!"],
    },
    {
      text: [
        <span key="2">Would you like me to explain how Parrot works?</span>,
      ],
      options: ["Yes", "No"],
    },
    {
      text: [
        <span key="3">
          Parrot helps you learn a new language <b>naturally</b>, by immersing
          you in <b>native content</b> and earning <b>water droplets</b> as you
          progress.
        </span>,
        <span key="4">
          You can earn water droplets by watching videos with{" "}
          <b>interactive subtitles</b> and completing <b>fun activities</b>. As
          you progress, your tree grows, and once fully grown, we{" "}
          <b>plant a real tree</b> on your behalf.
        </span>,
        <span key="5" className="flex justify-center w-full py-2 bg-white">
          <img src={tree} alt="tree" className="w-1/2" />
        </span>,
      ],
      options: ["Got it!"],
      condition: (selectedOptions: string[]) => selectedOptions.includes("Yes"),
    },
    {
      text: [
        <span key="7">
          Alright! What level would you say you are in Spanish?
        </span>,
      ],
      options: ["Beginner", "Intermediate", "Advanced"],
    },
    {
      text: [
        <span key="8">
          That's awesome, Parrot will help take you from {level} to beyond using
          highly researched techniques!
        </span>,
      ],
      options: ["Let's go!"],
    },
  ];

  const messages = messagesTemplate
    .filter(
      (message) => !message.condition || message.condition(selectedOptions),
    )
    .map((message, index) => ({
      text: message.text.map((t, i) =>
        React.cloneElement(t, { key: `${index}-${i}` }),
      ),
      options: message.options,
    }));

  // event to proceed through the chat sequence
  const handleOptionClick = (option: string) => {
    setSelectedOptions((prev) => [...prev, option]);

    // reached the last options
    if (currentStep == messages.length - 1) {
      nav("/home/learn");
    }

    if (currentStep === 3) {
      setLevel(option);
    }

    setCurrentStep((prevStep) => {
      let nextStep = prevStep + 1;
      while (
        nextStep < messages.length &&
        messages[nextStep].options.length === 0
      ) {
        nextStep++;
      }
      return nextStep;
    });
  };

  const handleBack = () => {
    if (currentStep === 0) {
      onEmailReset();
    } else {
      let previousStep = currentStep - 1;
      while (previousStep > 0 && !messages[previousStep].options) {
        previousStep--;
      }
      setCurrentStep(previousStep);
      setSelectedOptions(selectedOptions.slice(0, previousStep));
    }
  };

  return (
    <div className="flex flex-col h-full w-full items-center">
      <ChatHeader disableBack={disableBack || false} handleBack={handleBack} />
      <div className="flex-1 w-full overflow-y-auto no-scrollbar">
        <ChatBody
          messages={messages}
          currentStep={currentStep}
          selectedOptions={selectedOptions}
          handleOptionClick={handleOptionClick}
        />
      </div>
      <div className="h-12 w-full flex justify-end">
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant="ghost"
          textColor={"#6b7280"}
          fontSize={"small"}
          fontFamily={"Inter, sans-serif"}
          onClick={() => nav("/home/learn")}
        >
          Skip
        </Button>
      </div>
    </div>
  );
};

export default Chat;
