import React, { useMemo } from "react";
import ActivityButton from "../Buttons/ActivityButton";
import { TextBoxWithFlag, Flag, Text } from "../Video/TextBoxWithFlag";
import { ES } from "country-flag-icons/react/3x2";
import VideoPlayer from "../Video/VideoPlayer";
import { FillInTheBlankActivity, Option } from "../Types";

interface FillProps {
  fillInTheBlankActivity: FillInTheBlankActivity;
  onActivityFinished: () => void;
  lessonId: number;
  onTrackListeningTime: (time: number) => void;
}

const shuffleOptions = (options: Option[]): Option[] => {
  return options.sort(() => Math.random() - 0.5);
};

const Fill: React.FC<FillProps> = ({
  fillInTheBlankActivity,
  onActivityFinished,
  onTrackListeningTime,
  lessonId,
}) => {
  const fill = fillInTheBlankActivity.fillInTheBlank;
  const startTime = fillInTheBlankActivity.start;
  const endTime = fillInTheBlankActivity.end;
  const youtubePrefix = "youtube.com/watch?v=";
  const url = youtubePrefix + fillInTheBlankActivity.youtubeId;
  const options = useMemo(() => shuffleOptions(fill.options), [lessonId]);
  const handleClick = (selectedOption: Option) => {
    if (selectedOption.correct) {
      onActivityFinished();
    }
  };

  return (
    <div className="flex flex-col justify-start items-center w-full">
      <VideoPlayer
        url={url}
        onTimeUpdate={() => {}}
        startTime={startTime}
        endTime={endTime}
        onPlay={() => {}}
        onTrackListeningTime={onTrackListeningTime}
      />
      <div className="appPadding flex flex-col items-center w-full gap-2">
        <div className="w-full text-xl font-bold">
          Listen and fill in the blank
        </div>
        <div className="w-full rounded-xl bg-white p-4">
          <TextBoxWithFlag>
            <Flag>
              <ES title="Spain" />
            </Flag>
            <Text>{fill.question}</Text>
          </TextBoxWithFlag>
        </div>
        <div className="flex flex-col gap-3 w-52 pt-3">
          {options.map((option) => (
            <ActivityButton
              key={option.choice}
              text={option.choice}
              isCorrect={option.correct}
              onClick={() => handleClick(option)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fill;
