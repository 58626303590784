import React from "react";

const PrivacyPolicy: React.FC = () => {
  const headingPadding = "text-left py-6 px-2";
  return (
    <div className="max-w-5xl mx-auto p-5">
      <h1 className={`text-4xl font-bold ${headingPadding}`}>Privacy Policy for Parrot</h1>

      <p>
        <strong>Effective Date</strong>: September 2024
      </p>

      <p>
        Parrot is committed to protecting your privacy. This Privacy Policy
        explains how we collect, use, and share your personal information when
        you use our mobile application ("App") and related services.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>1. Information We Collect</h3>
      <p>We collect the following types of information:</p>
      <ul className="list-disc pl-5 text-left">
        <li>
          <strong>Personal Information</strong>: When you create an account, we
          collect personal information such as your name, email address, and
          payment details.
        </li>
        <li>
          <strong>Usage Information</strong>: We collect data on how you use the
          App, including your language learning progress, device information, IP
          address, and other usage metrics.
        </li>
        <li>
          <strong>Location Information</strong>: If you enable location
          services, we may collect your location data to personalize your
          learning experience.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies</strong>: We use cookies and
          similar technologies to gather usage data, improve functionality, and
          personalize your experience.
        </li>
      </ul>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>2. How We Use Your Information</h3>
      <p>We use the information we collect to:</p>
      <ul className="list-disc pl-5 text-left">
        <li>Provide and improve our Services.</li>
        <li>Track your progress and personalize your learning experience.</li>
        <li>
          Communicate with you, including sending service-related notifications.
        </li>
        <li>Process your payments for any premium services.</li>
        <li>Maintain security and prevent misuse of our platform.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>3. Sharing Your Information</h3>
      <p>
        We do not sell or rent your personal information. We may share your
        information in the following cases:
      </p>
      <ul className="list-disc pl-5 text-left">
        <li>
          <strong>Service Providers</strong>: With third-party companies that
          assist us in operating the Services, such as payment processors or
          hosting providers.
        </li>
        <li>
          <strong>Legal Compliance</strong>: When required by law or necessary
          to protect our rights.
        </li>
        <li>
          <strong>Business Transfers</strong>: In the event of a merger,
          acquisition, or asset sale, your information may be transferred.
        </li>
      </ul>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>4. Data Security</h3>
      <p>
        We take reasonable steps to protect your personal information from
        unauthorized access, loss, or misuse. However, no system is 100% secure,
        and we cannot guarantee the security of your data.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>5. Your Choices</h3>
      <p>You have the following rights regarding your personal information:</p>
      <ul className="list-disc pl-5 text-left">
        <li>
          <strong>Access and Update</strong>: You can access and update your
          personal information via your account settings.
        </li>
        <li>
          <strong>Delete</strong>: You can request deletion of your account and
          personal information by contacting us at{" "}
          <a href="mailto:support@fluentwithparrot.com">
            support@fluentwithparrot.com
          </a>
          .
        </li>
        <li>
          <strong>Opt-Out</strong>: You may opt-out of receiving marketing
          communications by following the unsubscribe link in our emails or by
          adjusting your account settings.
        </li>
      </ul>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>6. Data Retention</h3>
      <p>
        We retain your personal information as long as necessary to provide our
        Services or as required by law. Once your data is no longer needed, we
        will securely delete or anonymize it.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>7. Children's Privacy</h3>
      <p>
        Our Services are not intended for children under 13. We do not knowingly
        collect personal information from children under 13 without parental
        consent. If you believe we have collected such information, please
        contact us, and we will promptly delete it.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>8. International Data Transfers</h3>
      <p>
        If you are located outside of Canada, please note that your data may be
        transferred to and processed in Canada, where data protection laws may
        differ from your home country. By using our Services, you consent to
        this transfer.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>9. Changes to This Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted within the App or on our website. Continued use of the Services
        after such changes will indicate your acceptance of the revised Privacy
        Policy.
      </p>

      <h3 className={`text-2xl font-bold ${headingPadding}`}>10. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at{" "}
        <a href="mailto:support@fluentwithparrot.com">
          support@fluentwithparrot.com
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;