import React from "react";

interface InfoBoxProps {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-3 w-full px-6">
      <div className="text-xl font-semibold">{title}</div>
      <div >{subtitle}</div>
    </div>
  );
};

export default InfoBox;