import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./i18n";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme.ts";
import { PostHogProvider } from "posthog-js/react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const options = {
  api_host: "https://us.i.posthog.com",
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
     <PostHogProvider
      apiKey={"phc_GOJrt0SMSoxbhiaC2hX1SMEZOPg9MQ5sv8TeStF1JKu"}
      options={options}
    >
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
