import React from "react";
import { Card, CardBody } from "@chakra-ui/react";

interface DictionaryBlockProps {
  icon: string;
  title: string;
  subtitle: string | JSX.Element;
  onClick: () => void;
}

const DictionaryBlock: React.FC<DictionaryBlockProps> = ({
  icon,
  title,
  subtitle,
  onClick,
}) => {
  return (
    <button onClick={onClick}>
      <Card className="w-full h-auto" py={5}>
        <CardBody className="flex flex-col items-start justify-start gap-3 text-start">
          <div className="text-2xl font-semibold">{icon}</div>
          <div className="text-xl font-bold">{title}</div>
          <div className="text-sm font-normal">{subtitle}</div>
        </CardBody>
      </Card>
    </button>
  );
};

export default DictionaryBlock;
