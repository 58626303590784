import React from "react";
import { Avatar } from "@chakra-ui/react";
import logo from "../../assets/ParrotLogo.png";

interface ParrotMessageProps {
  message: React.ReactNode;
}

const ParrotMessage: React.FC<ParrotMessageProps> = ({ message }) => {
  return (
    <div className="flex justify-start w-full h-auto mb-2">
      <Avatar src={logo} size="sm" mr={2} />
      <div className="text-[#303437] bg-[#F2F4F5] rounded-xl rounded-tl-none p-3 w-3/4 text-start">
        {message}
      </div>
    </div>
  );
};

export default ParrotMessage;