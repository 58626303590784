import React from "react";
import { CircularProgress } from "@chakra-ui/react";

interface ChapterNodeProps {
  status: "completed" | "current" | "locked";
  icon: React.ReactNode;
  bgColor: string;
  borderColor: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ChapterNode: React.FC<ChapterNodeProps> = ({
  status,
  icon,
  bgColor,
  borderColor,
  disabled,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={` ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
    >
      <div className="relative flex items-center justify-center p-2">
        {status === "current" && (
          <div className="absolute justify-center items-center z-0">
            <CircularProgress
              value={25}
              size="90px"
              thickness="8px"
              color="var(--color-yellow)"
            />
          </div>
        )}
        <div
          className={`flex items-center justify-center w-14 h-14 rounded-3xl shadow-lg border-b-[6px] z-10 ${bgColor} ${borderColor}`}
        >
          {icon}
        </div>
      </div>
    </button>
  );
};

export default ChapterNode;