import { StatBlock, TimeStatBlock } from "@/components/StatBlock";
import React from "react";
import InfoBox from "@/components/InfoBox";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { getUserStats, callWithAuth } from "@/api/Api";
import { useQuery } from "@tanstack/react-query";
import { LessonStats } from "@/pages/UseLessonStats";
import { USER_STATS_QUERY_KEY } from "@/query_keys";

interface MotivationPageProps {
  title: React.ReactNode;
  subtitle: string;
  buttonText: string;
  buttonAction: () => void;
  lessonStats: LessonStats;
}

const MotivationPage: React.FC<MotivationPageProps> = ({
  title,
  subtitle,
  buttonText,
  buttonAction,
  lessonStats,
}) => {
  const userStatsQuery = useQuery({
    queryKey: [USER_STATS_QUERY_KEY],
    queryFn: () => {
      return callWithAuth(getUserStats);
    },
  });

  const listeningTime = userStatsQuery.data?.listeningTime || 0;
  const wordsKnownCount = userStatsQuery.data?.wordsKnown || 0;
  const waterDropletsCount = userStatsQuery.data?.waterDropletsCount || 0;

  return (
    <div className="appPadding flex flex-col items-center justify-between w-full h-full overflow-hidden">
      <div className="flex flex-row justify-center items-center w-full gap-2 pt-16">
        <TimeStatBlock
          timeSeconds={listeningTime}
          preText="⏰ "
          statDiff={lessonStats.listenTimeAdded}
        />
        <StatBlock
          value={wordsKnownCount}
          preText="🇪🇸 "
          subtitle="Known Words"
          statDiff={lessonStats.knownWordsAdded}
        />
        <StatBlock
          value={waterDropletsCount}
          preText="💧 "
          subtitle="Water Drops"
          statDiff={lessonStats.dropsAdded}
        />
      </div>
      <div className="flex-grow flex w-full items-center justify-center">
        <InfoBox title={title} subtitle={subtitle} />
      </div>
      <div className="w-full pb-16">
        <ContinueButton onClick={buttonAction} text={buttonText} />
      </div>
    </div>
  );
};

export default MotivationPage;
