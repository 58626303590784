import React from "react";
import { FaLock } from "react-icons/fa";
import ChapterNode from "./ChapterNode";

const ChapterNodeLocked: React.FC = () => {
  return (
    <ChapterNode
      status="locked"
      icon={<FaLock className="text-[#afafaf] w-6 h-6" />}
      bgColor="bg-neutral-200"
      borderColor="border-[#afafaf]"
      disabled={true}
    />
  );
};

export default ChapterNodeLocked;