import React from "react";

interface CardProps {
  card: CardItem;
  className: string;
}

interface CardItem {
  id: number;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const InsightsCard: React.FC<CardProps> = ({ card, className }) => {
  return (
    <li
      className={`bg-white rounded-3xl border border-gray-200 p-4 shadow-md w-full h-32 transition-all duration-700 ease-in-out opacity-0 absolute transform scale-85 translate-y-12 flex flex-col items-start justify-center ${className}`}
    >
      <div className="text-xl text-start">{card.title}</div>
      <div className="text-gray-600 text-start text-base mt-3">{card.subtitle}</div>
    </li>
  );
};

export default InsightsCard;