// JavaScript SDK
import PocketBase, { RecordAuthResponse } from "pocketbase";
import { API_BASE_URL } from "./Api.tsx";
import { tokenStorage } from "./tokenStorage";

const pb = new PocketBase(API_BASE_URL);

// Define types for user and authentication data if you have specific types, or use `any` if not available
interface User {
  email: string;
  password: string;
  name: string;
}

export function logout() {
  pb.authStore.clear();
  tokenStorage.clearToken();
}

// Async function to sign up a new user
// sets the token in storage to the new signed in user
export async function signUpUser(
  email: string,
  password: string,
  name: string,
): Promise<User> {
  try {
    const user = (await pb.collection("users").create({
      email: email,
      password: password,
      passwordConfirm: password,
      name: name,
    })) as User;
    await signInUser(email, password);
    return user;
  } catch (error) {
    console.error("Error signing up user:", error);
    throw error;
  }
}

export async function signOutUser() {
  try {
    pb.authStore.clear(); // No need to await this
    tokenStorage.clearToken();
  } catch (err) {
    console.error(err);
  }
}

// Async function to sign in a user with email and password
// sets the token in storage to the new signed in user
export async function signInUser(
  email: string,
  password: string,
): Promise<RecordAuthResponse> {
  try {
    const authData = await pb
      .collection("users")
      .authWithPassword(email, password);
    tokenStorage.setToken(authData.token);
    return authData;
  } catch (error) {
    console.error("Error signing in user:", error);
    throw error;
  }
}

// this just provides the ui with pb.authStore.token
// further calls needed to ensure user is created
export async function signInWithGoogle(): Promise<RecordAuthResponse> {
  pb.authStore.clear();
  const res = await signInWithOAuth2("google");
  const pbUid = res.record?.id || "";
  const name = res.meta?.rawUser?.given_name || res.meta?.name || "";
  const avatarUrl = res.meta?.avatarUrl || "";
  try {
    if (pbUid) {
      await pb.collection("users").update(pbUid, {
        name: name,
        avatarUrl: avatarUrl,
      });
    }
  } catch (err) {
    console.error(err);
  }
  const token = pb.authStore.token;
  // adjust the auth store so it adds the display name,
  tokenStorage.setToken(token);
  return res;
}

// Async function to sign in or sign up with OAuth2
async function signInWithOAuth2(provider: string): Promise<RecordAuthResponse> {
  try {
    const authData = await pb.collection("users").authWithOAuth2({ provider });
    return authData;
  } catch (error) {
    console.error("Error with OAuth2 sign-in:", error);
    throw error;
  }
}

// Async function to send a verification email
export async function sendVerificationEmail(email: string): Promise<void> {
  try {
    await pb.collection("users").requestVerification(email);
    console.log("Verification email sent");
  } catch (error) {
    console.error("Error sending verification email:", error);
    throw error;
  }
}

// Async function to send a password reset email
export async function sendPasswordResetEmail(email: string): Promise<void> {
  try {
    await pb.collection("users").requestPasswordReset(email);
    console.log("Password reset email sent");
  } catch (error) {
    console.error("Error sending password reset email:", error);
    throw error;
  }
}

// Async function to send a request to change the email
export async function sendEmailChangeRequest(newEmail: string): Promise<void> {
  try {
    await pb.collection("users").requestEmailChange(newEmail);
    console.log("Email change request sent");
  } catch (error) {
    console.error("Error sending email change request:", error);
    throw error;
  }
}
