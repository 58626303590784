import React from "react";
import Title from "../Title";
import CourseView from "../Course/CourseView";
import { getUserInfo, callWithAuth, getUserStats } from "../../api/Api";
import { useQuery } from "@tanstack/react-query";
import CardCarousel from "../Carousel/CardCarousel";

const LearnTab: React.FC = () => {
  const userInfoQuery = useQuery({
    queryKey: ["userInfo"],
    queryFn: () => {
      return callWithAuth(getUserInfo);
    },
  });

  const firstName = userInfoQuery.data?.firstName || "";
  const level = 1;

  const userStatsQuery = useQuery({
    queryKey: ["userStats"],
    queryFn: () => {
      return callWithAuth(getUserStats);
    },
  });

  const listeningTime = userStatsQuery.data?.listeningTime || 0;
  const timeToNextLevel = userStatsQuery.data?.timeToNextLevel || 0;
  const treesPlanted = userStatsQuery.data?.treesPlanted || 0;
  const dropsNeeded = userStatsQuery.data?.waterDropletsNeeded || 0;
  const knownWordsCount = userStatsQuery.data?.wordsKnown || 0;
  const daysStreak = userStatsQuery.data?.dayStreak || 0;
  const nextLevel = (userStatsQuery.data?.level || 0) + 1;

  const cardItems = [
    {
      id: 1,
      title: (
        <div>
          You have listened to{" "}
          <span className="font-bold">
            {formatListeningTime(listeningTime)}
          </span>{" "}
          of Spanish 👏🏻
        </div>
      ),
      subtitle: (
        <div>
          Listen to{" "}
          <span className="font-semibold">
            {formatListeningTime(timeToNextLevel)}
          </span>{" "}
          more to level up to{" "}
          <span className="font-semibold">level {nextLevel}</span>
        </div>
      ),
    },
    {
      id: 2,
      title: (
        <div>
          You have planted{" "}
          <span className="font-bold">{treesPlanted} tree(s) 🌳</span>
        </div>
      ),
      subtitle: (
        <div>
          Earn{" "}
          <span className="font-semibold">{dropsNeeded} more droplets</span> to
          plant <span className="font-semibold">1 more tree</span>
        </div>
      ),
    },
    {
      id: 3,
      title: (
        <div>
          You have learned{" "}
          <span className="font-bold">{knownWordsCount} words</span> of Spanish
          🇪🇸
        </div>
      ),
      subtitle: (
        <div>
          Learn <span className="font-semibold">200 more words</span> to reach{" "}
          <span className="font-semibold">level {level + 1}</span>
        </div>
      ),
    },
    {
      id: 4,
      title: (
        <div>
          You have a <span className="font-bold">{daysStreak} day</span> streak
          🔥
        </div>
      ),
      subtitle: <div>Keep going and reach your language goals!</div>,
    },
  ];

  return (
    <div className="flex flex-col justify-start w-full h-full gap-4">
      <Title text={firstName + "'s Insights"} />
      <CardCarousel cardItems={cardItems} />
      <CourseView />
    </div>
  );
};

function formatListeningTime(seconds: number): string {
  const pluralize = (count: number, singular: string, plural: string) =>
    count === 1 ? `${count} ${singular}` : `${count} ${plural}`;

  if (seconds === 0) {
    return "0 minutes";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString = hours > 0 ? pluralize(hours, "hour", "hours") : "";
  const minutesString =
    minutes > 0
      ? pluralize(minutes, "minute", "minutes")
      : hours > 0
        ? "0 minutes"
        : "";
  const secondsString =
    hours === 0 && minutes === 0 && remainingSeconds > 0
      ? pluralize(remainingSeconds, "seconds", "seconds")
      : "";

  const timeParts = [hoursString, minutesString, secondsString].filter(
    (part) => part !== "",
  );

  return timeParts.join(", ");
}

export default LearnTab;
