import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

// Define the base component styles
const baseStyle = definePartsStyle({
  container: {
    backgroundColor: "white",
    _dark: {
      backgroundColor: "gray.800",
    },
    borderRadius: "12px",
    rounded: "24px",
    shadow: "none",
    fontFamily: "Inter, sans-serif",
    fontWeight: "normal",
  },
  header: {
    paddingBottom: "2px",
  },
  body: {
    paddingTop: "2px",
  },
  footer: {
    paddingTop: "4px",
  },
});

const sizes = {
  md: definePartsStyle({
    container: {
      borderRadius: "12px",
      padding: "12px",
    },
  }),
  xl: definePartsStyle({
    container: {
      borderRadius: "16px",
      padding: "12px",
    },
  }),
};

// Define custom variant
const variants = {
  blue: definePartsStyle({
    container: {
      backgroundColor: "brand.blue",
      color: "white",
      borderRadius: "16px",
      border:"1px rgba(0, 0, 0, 0.10) solid",
    },
  }),
  green: definePartsStyle({
    container: {
      backgroundColor: "#0C5427",
      color: "white",
      borderRadius: "16px",
      border:"1px rgba(0, 0, 0, 0.10) solid",
    },
  }),
  shadow: definePartsStyle({
    container: {
      color: "black",
      shadow: "md",
      border: "1px rgba(0, 0, 0, 0.10) solid",
      rounded: "12px",
    },
  }),
};

// Export the component theme
export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: "xl",
  },
});
