import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MotivationPage from "../../pages/MotivationPage";
import {
  callWithAuth,
  postLessonCompleted,
  PostLessonCompletedRequest,
  PostLessonCompletedResponse,
  postChapterCompleted,
  PostChapterCompletedRequest,
  PostChapterCompletedResponse,
} from "@/api/Api";
import { Lesson } from "../Types";
import { LessonStats } from "@/pages/UseLessonStats";
import SignUpPrompt from "./SignUpPrompt";

interface ModuleCompleteProps {
  lessonStats: LessonStats;
  isTempUser: boolean;
  chapterId: number;
  lessonId: number;
  isLastLesson: boolean;
  isLastChapter: boolean;
  nextLessonId: number;
  lesson: Lesson;
  onAddDroplets: (droplets: number) => void;
}

const ModuleComplete: React.FC<ModuleCompleteProps> = ({
  isTempUser,
  isLastLesson,
  isLastChapter,
  lessonStats,
  chapterId,
  lessonId,
  lesson,
  nextLessonId,
  onAddDroplets,
}) => {
  const navigate = useNavigate();
  const isLessonCompleteView = !isLastLesson;
  const isChapterCompleteView = isLastLesson;
  const isCourseCompleteView = isLastLesson && isLastChapter;

  const lessonNumber = lesson.sequence + 1;

  const handleNavigateToNextModule = () => {
    // to next lesson
    if (isLessonCompleteView) {
      navigate(`/chapter/${chapterId}/lesson/${nextLessonId}`);
      return;
    }
    if (isChapterCompleteView || isCourseCompleteView) {
      navigate(`/home/learn`);
    }
  };

  useEffect(() => {
    if (lessonId) {
      const data: PostLessonCompletedRequest = {
        lessonId: lessonId,
      };
      callWithAuth(postLessonCompleted, data).then(
        (res: PostLessonCompletedResponse) => {
          // TODO handle the water droplets awarded, for finishing lesson
          console.log(res.waterDropletsAwarded);
          onAddDroplets(res.waterDropletsAwarded);
        },
      );
      if (isChapterCompleteView) {
        const data: PostChapterCompletedRequest = {
          chapterId: chapterId,
        };
        callWithAuth(postChapterCompleted, data).then(
          (res: PostChapterCompletedResponse) => {
            // TODO handle the water droplets awarded, for finishing lesson
            onAddDroplets(res.waterDropletsAwarded);
          },
        );
      }
    }
  }, [lessonId]);

  if (lessonNumber === 1 && isTempUser) {
    return (
      <SignUpPrompt
        buttonAction={() => navigate("/signup")}
        lessonStats={lessonStats}
      />
    );
  }

  if (isCourseCompleteView) {
    return (
      <MotivationPage
        lessonStats={lessonStats}
        title={
          <>
            Course <span className="text-[var(--color-green)]">Completed</span>{" "}
            🎉 🎉 🎉
          </>
        }
        subtitle="Fantastic! Keep up the great work with Parrot and you could be fluent in no time!"
        buttonText="Back to home"
        buttonAction={() => navigate("/home/learn")}
      />
    );
  }

  if (isChapterCompleteView) {
    return (
      <MotivationPage
        lessonStats={lessonStats}
        title={
          <>
            {"Chapter"}{" "}
            <span className="text-[var(--color-green)]">Completed</span> 🎉 🎉
            🎉
          </>
        }
        subtitle="Fantastic! Keep up the great work with Parrot and you could be fluent in no time!"
        buttonText="Back to home"
        buttonAction={() => navigate("/home/learn")}
      />
    );
  }

  return (
    <MotivationPage
      lessonStats={lessonStats}
      title={
        <>
          Lesson {lessonNumber}{" "}
          <span className="text-[var(--color-green)]">Completed</span> 🎉 🎉 🎉
        </>
      }
      subtitle="Great job! Keep up the great work with Parrot and you could be fluent in no time!"
      buttonText="Continue to next lesson"
      buttonAction={handleNavigateToNextModule}
    />
  );
};

export default ModuleComplete;
