import React from "react";
import { useNavigate } from "react-router-dom";
import animation from "../assets/full_tree_animation.mp4";

const PlantAnimation: React.FC = () => {
  const navigate = useNavigate();

  const handleVideoEnd = () => {
    navigate("/lesson");
  };

  return (
    <video autoPlay muted onEnded={handleVideoEnd}>
      <source src={animation} type="video/mp4"></source>
    </video>
  );
};

export default PlantAnimation;
