import React from "react";
import { useSwipeable } from "react-swipeable";

interface TabContentProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  content: React.ReactNode[];
}

const TabContent: React.FC<TabContentProps> = ({ activeTab, setActiveTab, content }) => {
  const handleSwipedLeft = () => {
    setActiveTab(activeTab < content.length - 1 ? activeTab + 1 : activeTab);
  };

  const handleSwipedRight = () => {
    setActiveTab(activeTab > 0 ? activeTab - 1 : activeTab);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
  });

  return (
    <div className="w-full h-full" {...handlers}>
      {content[activeTab]}
    </div>
  );
};

export default TabContent;