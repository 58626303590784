import React from "react";
import { CloseButton } from "@chakra-ui/react";
import { UserWord } from "../Types";
import { useQuery } from "@tanstack/react-query";
import { getWordInfo, callWithAuth, GetWordInfoRequest } from "../../api/Api";

interface WordInfoViewProps {
  word: UserWord | null;
  onBack: () => void;
}

const WordInfoView: React.FC<WordInfoViewProps> = ({ word, onBack }) => {
  const infoId = word!.wordKey;

  const wordInfoQuery = useQuery({
    queryKey: ["wordInfo"],
    queryFn: () => {
      const data: GetWordInfoRequest = {
        wordKey: infoId,
      };
      return callWithAuth(getWordInfo, data);
    },

  });

  const info = wordInfoQuery.data || {
    definition: "",
    sentence: "",
    otherUsage: ""
  };

  return (
    <div className="bg-white p-4 rounded-xl flex flex-col justify-start items-start gap-2 text-start">
      <div className="flex justify-between w-full items-center">
        <div className="font-bold text-2xl">"{word!.targetWord}" - {word!.nativeWord}</div>
        <CloseButton onClick={onBack} />
      </div>
      <div className="text-lg font-bold">Definition</div>
      <div className="text-lg">{info.definition}</div>
      <div className="text-lg font-bold">Example usage</div>
      <div className="text-lg">{info.sentence}</div>
      <div className="text-lg font-bold">Different uses</div>
      <div className="text-lg">{info.otherUsage}</div>
    </div>
  );
};

export default WordInfoView;