import React from "react";
import { PiDropFill } from "react-icons/pi";
import ChapterNode from "./ChapterNode";

const ChapterNodeCompleted: React.FC<{ onClick: () => void }> = ({ onClick }) => {

  return (
    <ChapterNode
      status="completed"
      icon={<PiDropFill className="w-8 h-8 text-white" />}
      bgColor="bg-[var(--color-blue)]"
      borderColor="border-[var(--color-dark-blue)]"
      onClick={onClick}
    />
  );
};

export default ChapterNodeCompleted;