import React from "react";
import {
  Stack,
  // Divider,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  InputRightElement,
  InputGroup,
  // AbsoluteCenter,
  //Box,
  Link,
  Text,
} from "@chakra-ui/react";
import ParrotText from "@/components/ParrotText";
import { useToast } from "@chakra-ui/react";
//import GoogleButton from "@/components/Buttons/GoogleButton";
import { useQuery } from "@tanstack/react-query";
import {
  callWithAuth,
  postNewUser,
  putTempUserToFullUser,
  PutTempUserToFullUserRequest,
  GetUserInfoResponse,
  axiosInstance,
} from "../api/Api.tsx";
import { tokenStorage } from "../api/tokenStorage.tsx";
import { signUpUser } from "../api/login.tsx";
import { Navigate, useNavigate } from "react-router-dom";
import BackButton from "@/components/Buttons/BackButton";

const SignUpPage: React.FC = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [show, setShow] = React.useState(false);
  const isTokenSet = tokenStorage.isTokenSet();
  const { isPending, data } = useQuery({
    queryKey: ["UserInfoQuerySignUp"],
    retry: 1,
    queryFn: async () => {
      const authToken = tokenStorage.getToken();
      const response = await axiosInstance.get<GetUserInfoResponse>(
        "/auth/userinfo",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        },
      );
      return response.data;
    },
  });
  const userInfo = data;

  const nav = useNavigate();
  const toast = useToast();

  const handleCreateAccount = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let displayName;
    if (name.trim() === "" && userInfo && userInfo.firstName) {
      displayName = userInfo.firstName;
    } else {
      displayName = name;
    }

    if (displayName.trim() === "") {
      toast({
        title: "Name required",
        description: "Please enter your name",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else if (email.trim() === "") {
      toast({
        title: "Email required",
        description: "Please enter an email address",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else if (!emailRegex.test(email)) {
      toast({
        title: "Invalid email format",
        description: "Please enter a valid email address",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else if (password.length < 8) {
      toast({
        title: "Password too short",
        description: "Password must be at least 8 characters long",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        if (userInfo && userInfo.isTemp) {
          await signUpUser(email, password, displayName);
          const data: PutTempUserToFullUserRequest = {
            parrotUserId: userInfo.id,
          };
          await callWithAuth(putTempUserToFullUser, data);
          nav("/home/learn");
        } else {
          await signUpUser(email, password, displayName);
          await callWithAuth(postNewUser);
          nav(`/onboarding/${displayName}/${email}`);
        }
      } catch (err: any) {
        let uiErrMsg = pocketBaseUiErrorMesg(err);
        if (!uiErrMsg) {
          uiErrMsg = "Sign-up failed please try again.";
        }
        toast({
          title: "Sign-up failed",
          description: `${uiErrMsg}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  {/* const handleGoogleSignUp = async () => {
    try {
      if (userInfo && userInfo.isTemp) {
        await signInWithGoogle();
        const data: PutTempUserToFullUserRequest = {
          parrotUserId: userInfo.id,
        };
        await callWithAuth(putTempUserToFullUser, data);
        nav("/home/learn");
      } else {
        await signInWithGoogle();
        await callWithAuth(postNewUser);
        nav("/onboarding");
      }
    } catch (err: any) {
      let uiErrMsg = pocketBaseUiErrorMesg(err);
      if (!uiErrMsg) {
        uiErrMsg = "Sign-up failed please try again.";
      }
      toast({
        title: "Sign-up failed",
        description: `${uiErrMsg}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };*/}

  const pocketBaseUiErrorMesg = (err: any) => {
    const nameProblem = err.response?.data?.name;
    const emailProblem = err.response?.data?.email;
    const passProblem = err.response?.data?.password;
    if (emailProblem) {
      return emailProblem.message;
    }
    if (nameProblem) {
      return nameProblem.message;
    }
    if (passProblem) {
      return passProblem.message;
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePassswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPassword(event.target.value);
  };

  // todo, have a page loading component
  if (isTokenSet && isPending) return "Loading...";

  // redirect to home
  // full user accidently end up here
  if (userInfo && userInfo.isTemp == false) {
    return <Navigate to="/home/learn" />;
  }

  // TODO IDEAS FOR THE UI,
  // 1. show the stats if the user is a temp user
  // 2. Show the time until temp account deleted counting down if a temp user is there
  return (
    <Stack
      width={"100%"}
      height={"100%"}
      justify={"center"}
      align={"center"}
      justifyContent={"space-between"}
      overflow={"hidden"}
      className="appPadding overflow-hidden"
    >
      <div className="self-start">
        <BackButton
          handleBack={() => {
            nav("/");
          }}
        />
      </div>
      <Stack align="center" spacing={8} width={"100%"}>
        <ParrotText />
        <Stack align="center" spacing={0} paddingBottom={4}>
          {userInfo && userInfo.isTemp ? (
            <div className="text-black text-center text-xl font-semibold my-1">
              Wooo, yes {userInfo?.firstName || ""} <br /> Time to save your
              progress!
            </div>
          ) : (
            <div className="text-black text-center text-xl font-semibold my-1">
              Get started with Parrot
            </div>
          )}
          <div className="text-sm">
            Sign up and start your journey to fluency!
          </div>
        </Stack>
      </Stack>

      <Stack
        align="center"
        spacing={4}
        width={"100%"}
        bg={"white"}
        padding={4}
        rounded={"2xl"}
      >
        <Stack spacing={2} width={"100%"}>
          {!userInfo && (
            <FormControl isRequired>
              <FormLabel fontSize={"sm"} marginBottom={1}>
                First Name
              </FormLabel>
              <Input value={name} isRequired onChange={handleNameChange} />
            </FormControl>
          )}

          <FormControl isRequired>
            <FormLabel fontSize={"sm"} marginBottom={1}>
              Email
            </FormLabel>
            <Input value={email} isRequired onChange={handleEmailChange} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize={"sm"} marginBottom={1}>
              Password
            </FormLabel>
            <InputGroup size="md">
              <Input
                value={password}
                type={show ? "text" : "password"}
                isRequired
                onChange={handlePassswordChange}
              />
              <InputRightElement width="4.5rem">
                <Button size="sm" onClick={() => setShow(!show)}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText textAlign={"start"}>
              {" "}
              At least 8 characters long
            </FormHelperText>
          </FormControl>
        </Stack>

        <Stack width={"100%"} gap={1}>
          <Button
            type="button"
            background={"var(--color-blue)"}
            textColor={"white"}
            width={"100%"}
            onClick={handleCreateAccount}
          >
            Create account
          </Button>
          <Text fontSize={"sm"}>
            Already have an account?{" "}
            <Link href="/login" color="var(--color-blue)">
              Login
            </Link>
          </Text>
        </Stack>

        {/*
        <Box width={"100%"} position={"relative"}>
          <Divider />
          <AbsoluteCenter
            bg="white"
            px="4"
            textColor={"gray.500"}
            fontSize={"sm"}
          >
            OR
          </AbsoluteCenter>
        </Box>
        <Stack spacing={4} width={"100%"}>
          <GoogleButton onClick={handleGoogleSignUp} />
        </Stack>
        */}
      </Stack>
      <div className="w-full min-h-10"></div>
    </Stack>
  );
};

export default SignUpPage;
