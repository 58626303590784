// tokenStorage.ts
const TOKEN_KEY = "authToken";

export const tokenStorage = {
  // Function to retrieve the token
  getToken: (): string => {
    const token = localStorage.getItem(TOKEN_KEY);
    return token || "";
  },

  // Function to set the token
  setToken: (token: string): void => {
    localStorage.setItem(TOKEN_KEY, token);
  },

  // Function to check if the token is set
  isTokenSet: (): boolean => {
    const token = localStorage.getItem(TOKEN_KEY);
    return token !== null && token !== "";
  },

  // Function to clear the token
  clearToken: (): void => {
    localStorage.removeItem(TOKEN_KEY);
  },
};
