import React from "react";
import Droplets from "./Droplets";
import { IonHeader, IonToolbar } from "@ionic/react";
import { SpainFlag as ES } from "./SpainFlag";
import TabButtons from "../Tabs/TabButtons";

interface HeaderProps {
  activePage: string;
  activeTab?: number;
  setActiveTab?: (tab: number) => void;
  tabNames?: string[];
}

const Header: React.FC<HeaderProps> = ({
  activePage,
  activeTab,
  setActiveTab,
  tabNames,
}) => {
  const renderHeaderContent = () => {
    switch (activePage) {
      case "learn":
        return (
          <div className="py-2 font-medium">
            {tabNames &&
              setActiveTab !== undefined &&
              activeTab !== undefined && (
                <TabButtons
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabNames={tabNames}
                />
              )}
          </div>
        );
      case "dictionary":
        return <div className="py-2 font-medium">Dictionary</div>;
      case "profile":
        return <div className="py-2 font-medium">Profile</div>;
      default:
        return null;
    }
  };

  return (
    <IonHeader translucent={false} className="w-full h-full bg-inherit">
      <IonToolbar>
        <div className="w-full h-full flex flex-row items-center justify-between p-4 bg-[#fafafa] relative">
          <ES />
          <div className="absolute left-1/2 transform -translate-x-1/2 z-10">
            {renderHeaderContent()}
          </div>
          <Droplets pulse={false} />
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
