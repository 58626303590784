import React, { useState } from "react";
import { CloseButton, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import Title from "../Title";
import { SearchIcon } from "@chakra-ui/icons";
import WordListView from "./WordListView";
import WordInfoView from "./WordInfoView";
import { UserWord } from  "../Types";

interface DictionaryViewProps {
  type: "Known" | "Learning";
  onClose: () => void;
}

const DictionaryView: React.FC<DictionaryViewProps> = ({ type, onClose }) => {
  const [selectedWord, setSelectedWord] = useState<UserWord | null>(null);
  
  return (
    <div className="flex flex-col justify-start w-full h-full gap-4 pb-4">
      <div className="flex flex-row justify-between items-center">
        <Title text={type} />
        <CloseButton onClick={onClose} />
      </div>
      <InputGroup bg={"white"} border={"none"} rounded={"xl"}>
        <InputLeftElement><SearchIcon /></InputLeftElement>
        <Input placeholder="Search..." bg={"white"} border={"none"}/>
      </InputGroup>
      {selectedWord ? (
        <WordInfoView word={selectedWord} onBack={() => setSelectedWord(null)} />
      ) : (
        <WordListView type={type} onWordSelect={setSelectedWord} />
      )}
    </div>
  );
};

export default DictionaryView;