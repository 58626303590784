import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, useToast } from "@chakra-ui/react";
import ParrotText from "../components/ParrotText";
import ContinueButton from "../components/Buttons/ContinueButton";
import BackButton from "../components/Buttons/BackButton";

interface NameInputProps {
  onNameSubmit: (name: string) => void;
}

const NameInput: React.FC<NameInputProps> = ({ onNameSubmit }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();
  const nav = useNavigate();

  const handleContinue = () => {
    if (name.trim() !== "") {
      onNameSubmit(name);
    } else {
      setError(true);
      toast({
        title: "Name required",
        description: "Please enter your first name before continuing.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleBack = () => {
    nav("/");
  };

  return (
    <div className="flex flex-col h-full w-full overflow-hidden items-center justify-between">
      <div className="w-full">
        <div className="w-full flex justify-start">
          <BackButton handleBack={handleBack} />
        </div>
        <div className="w-full pt-12">
          <ParrotText />
        </div>
      </div>
      <div className="w-3/4">
        <Input
          value={name}
          onChange={handleInputChange}
          focusBorderColor="brand.blue"
          borderColor={error ? "red.500" : "gray.300"}
          placeholder="Enter your first name here"
          background="white"
          width="full"
        />
      </div>
      <div className="w-full p-2 pb-16">
        <ContinueButton onClick={handleContinue} />
      </div>
    </div>
  );
};

export default NameInput;
