import React from "react";
import { IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

interface BackButtonProps {
  handleBack: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ handleBack}) => {
  return (
    <IconButton
        variant="ghost"
        aria-label="back"
        icon={<ChevronLeftIcon w={8} h={8} />}
        onClick={handleBack}
    />
  );
};

export default BackButton;