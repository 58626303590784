import React from "react";
import { PiDropFill } from "react-icons/pi";
import { useQuery } from "@tanstack/react-query";
import { callWithAuth, getWaterDroplets } from "../../api/Api";
import "./Droplets.css";
import { WATER_DROPLETS_QUERY_KEY } from "@/query_keys";

const Droplets: React.FC<{ pulse: boolean }> = ({ pulse }) => {
  const query = useQuery({
    queryKey: [WATER_DROPLETS_QUERY_KEY],
    queryFn: () => {
      return callWithAuth(getWaterDroplets);
    },
  });

  return (
    <div className={`h-6 justify-start items-center inline-flex bg-[var(--color-blue)] rounded-lg p-[6px] gap-1 ${pulse ? 'drops-animation animate-bounce' : ''}`}>
      <PiDropFill className="w-3 h-3 text-white" />
      <div className="text-center text-white text-sm font-semibold">
        {query.data?.waterDropletsCount}
      </div>
      {pulse && (
        <>
          <div className="blob-3"></div>
        </>
      )}
    </div>
  );
};

export default Droplets;
