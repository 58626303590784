import React from "react";
import BackButton from "../Buttons/BackButton";
import Title from "../Title";

interface ChapterHeaderProps {
  title: string;
  handleBack: () => void;
}

const ChapterHeader: React.FC<ChapterHeaderProps> = ({ title, handleBack }) => {

  return (
    <div className="flex flex-row justify-start items-center w-full h-auto mb-7">
      <BackButton handleBack={handleBack} />
      <Title text={title} />
    </div>
  );
};

export default ChapterHeader;
