import React, { ReactNode } from "react";

interface FlagProps {
  children: ReactNode;
}

const Flag: React.FC<FlagProps> = ({ children }) => {
  return (
    <div className="w-8 rounded-xl flex items-center justify-center">
      {children}
    </div>
  );
};

interface TextProps {
  children: ReactNode;
}

const Text: React.FC<TextProps> = ({ children }) => {
  return <div className="text-base font-semibold w-full">{children}</div>;
};

interface TextBoxWithFlagProps {
  children: ReactNode;
  className?: string;
}

const TextBoxWithFlag: React.FC<TextBoxWithFlagProps> = ({ children, className }) => {
  const isReactElement = (child: ReactNode): child is React.ReactElement => {
    return typeof child === 'object' && child !== null && 'type' in child;
  };

  const flag = React.Children.toArray(children).find(
    (child) => isReactElement(child) && child.type === Flag
  );
  const text = React.Children.toArray(children).find(
    (child) => isReactElement(child) && child.type === Text
  );

  return (
    <div className={`flex flex-row items-center justify-start text-start w-full gap-4 ${className}`}>
      {flag}
      {text}
    </div>
  );
};

export { TextBoxWithFlag, Flag, Text };