import React from "react";
import Title from "../Title";
import { SimpleGrid } from "@chakra-ui/react";
import { StatBlock } from "../StatBlock";
import DropsHighlight from "../DropsHighlight";
import { getUserInfo, callWithAuth, getPlantStats } from "../../api/Api";
import { useQuery } from "@tanstack/react-query";
import one from "../../assets/tree1.png";
import two from "../../assets/tree2.png";
import three from "../../assets/tree3.png";
import four from "../../assets/tree4.png";
import five from "../../assets/tree5.png";
import six from "../../assets/tree6.png";

const GrowTab: React.FC = () => {
  const userInfoQuery = useQuery({
    queryKey: ["userInfo"],
    queryFn: () => {
      return callWithAuth(getUserInfo);
    },
  });

  const firstName = userInfoQuery.data?.firstName || "";

  const plantStatsQuery = useQuery({
    queryKey: ["plantStats"],
    queryFn: () => {
      return callWithAuth(getPlantStats);
    },
  });

  const plantStats = plantStatsQuery.data || {
    waterDropletsCount: 0,
    waterDropletsNeeded: 0,
    chaptersCompleted: 0,
    treesPlanted: 0,
    co2Captured: 0.0,
  };

  const plantSizePercentage =
    plantStats.waterDropletsCount / plantStats.waterDropletsNeeded;

  let plant;
  if (plantSizePercentage >= 0.833) {
    plant = six;
  } else if (plantSizePercentage >= 0.666) {
    plant = five;
  } else if (plantSizePercentage >= 0.5) {
    plant = four;
  } else if (plantSizePercentage >= 0.333) {
    plant = three;
  } else if (plantSizePercentage >= 0.166) {
    plant = two;
  } else {
    plant = one;
  }

  return (
    <div className="flex flex-col justify-start w-full h-full overflow-scroll no-scrollbar">
      <Title text={firstName + "'s Plant"} />
      <div className="w-full px-28 pb-4 pt-20 rounded-3xl bg-white">
        <img src={plant} alt="Tree" className="w-full h-auto mt-2" />
      </div>
      <div className="pt-4">
        <DropsHighlight
          dropsCount={plantStats.waterDropletsCount}
          dropsNeeded={plantStats.waterDropletsNeeded}
        />
      </div>
      <SimpleGrid
        columns={2}
        spacing={3}
        justifyItems="center"
        className="w-full pt-4 pb-4"
      >
        <StatBlock
          value={plantStats.waterDropletsCount}
          postText=" 💧"
          subtitle="Water Droplets"
        />
        <StatBlock
          value={plantStats.chaptersCompleted}
          postText=" 🍃"
          subtitle="Courses Completed"
        />
        <StatBlock
          value={plantStats.treesPlanted}
          postText=" 🌳"
          subtitle="Trees Planted"
        />
        <StatBlock
          value={plantStats.co2Captured}
          postText=" kg 💨"
          subtitle="CO2 Captured"
        />
      </SimpleGrid>
    </div>
  );
};

export default GrowTab;
